import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import feedbackFormImg from "../../../../assets/img/catalogPage/feedbackFormImg.png";
import feedbackFormIcon from "../../../../assets/img/catalogPage/feedbackFormIcon.png";
import style from "./feedbackForm.module.scss";
import { Checkbox } from "@mui/material";

import { SvgInButton } from "../../../../assets/svg/svgInButton";
import {
  IconCheckbox,
  IconCheckboxChecked,
} from "../../../../assets/svg/iconCheckbox";
import sendEmail from "../../../common/ModalWindows/sendEmail";

const FeedbackForm = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Это поле обязательное для заполнения"),
    numberPhone: Yup.string().required("Это поле обязательное для заполнения"),
    checkbox: Yup.bool().oneOf(
      [true],
      "Пожалуйста,  примите наши условия, прежде чем продолжить."
    ),
  });

  const onSubmit = (values, { resetForm }) => {
    // Отправка данных на почту
    sendEmail(values);
    // Сброс формы после отправки
    resetForm();
  };
  return (
    <section className={style.feedbackForm}>
      <img
        className={style.feedbackForm__img}
        src={feedbackFormImg}
        alt="ceiling"
      ></img>
      <div className={style.feedbackForm__wrapperForm}>
        <img
          className={style.iconMobile}
          src={feedbackFormIcon}
          alt="icon"
        ></img>
        <h3 className={style.title}>Хотите узнать цену?</h3>
        <p className={style.subtitle}>
          Вы можете задать вопрос или получить консультацию, заказав обратный
          звонок
        </p>
        <Formik
          initialValues={{ name: "", numberPhone: "", checkbox: false }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className={style.form}>
            <p className={style.text}>Ваше имя</p>
            <Field
              className={style.name}
              type="text"
              name="name"
              placeholder="Имя"
            ></Field>
            <div className={style.wrapperError}>
              <ErrorMessage name="name" component="p" className={style.error} />
            </div>
            <p className={style.text}>Ваш телефон</p>
            <Field
              className={style.numberPhone}
              type="text"
              name="numberPhone"
              placeholder="+7(_ _ _) _ _ _ -_ _ -_ _"
            ></Field>
            <div className={style.wrapperError}>
              <ErrorMessage
                name="numberPhone"
                component="p"
                className={style.error}
              />
            </div>
            <button className={style.button} type="submit">
              <div className={style.wrapperTextBtn}>
                <p className={style.buttonText}>Отправить</p>
                <SvgInButton />
              </div>
            </button>

            <div className={style.wrapperCheckbox}>
              <Field name="checkbox">
                {({ field }) => (
                  <Checkbox
                    {...field}
                    icon={<IconCheckbox />}
                    checkedIcon={<IconCheckboxChecked />}
                  />
                )}
              </Field>

              <p className={style.wrapperCheckbox__text} for="checkbox-id">
                Согласен на обработку персональных данных
              </p>
            </div>
            <div className={style.wrapperError}>
              <ErrorMessage
                name="checkbox"
                component="p"
                className={style.error}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </section>
  );
};

export default FeedbackForm;
