import { SvgInButton } from "../../../assets/svg/svgInButton";
import style from "./buttons.module.scss";

const MainButton = ({ text, theme }) => {
  return (
    <button
      className={
        theme === "light" ? style.mainButtonLight : style.mainButtonDark
      }
    >
      <div className={style.wrapperText}>
        <p
          className={
            theme === "light"
              ? style.mainButtonTextLight
              : style.mainButtonTextDark
          }
        >
          {text}
        </p>
        <div
          className={
            theme === "light" ? style.mainButtonSvg : style.mainButtonSvgDark
          }
        >
          <SvgInButton />
        </div>
      </div>
    </button>
  );
};
export default MainButton;
