import Button from "../../../common/Buttons/button";
import { Link } from "react-router-dom";
import { dataOurPrices } from "./constants/dataOurPrices";
import style from "./ourPrices.module.scss";

const OurPrices = () => {
  return (
    <section className={style.ourPrices}>
      <div className={style.wrapper}>
        <h1 className={style.ourPrices__title}>НАШИ ЦЕНЫ ВАС приятно удивят</h1>
        <p className={style.ourPrices__subtitle}>
          Мы стараемся подобрать потолок на любой вкус и кошелёк
        </p>
        <div className={style.ourPrices__blocks}>
          {dataOurPrices.map((block, index) => {
            return (
              <div className={style.block} key={index}>
                <div className={style.icon}>{block.icon}</div>
                <h3 className={style.title}>{block.title}</h3>
                <p className={style.price}>
                  <span className={style.bold}>{block.price}</span>₽/М²
                </p>
                <p className={style.specifications}>Характеристики</p>
                <p
                  className={style.width}
                >{`Ширина полотна: ${block.width}`}</p>
                <p
                  className={style.production}
                >{`Производство: ${block.production}`}</p>
                <p className={style.text}>Цена при заказе потолков от 30 м²</p>
                <p
                  className={style.guarantee}
                >{`Гарантия: ${block.guarantee}`}</p>
              </div>
            );
          })}
        </div>
        <Link className={style.ourPrices__button} to="/prices">
          <Button text="Все цены" underline="black" />
        </Link>
      </div>
    </section>
  );
};

export default OurPrices;
