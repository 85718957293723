export const listOurWorks = [
  { name: "Отзывы", link: "/#reviews", id: "reviews" },
  { name: "Цены", link: "/prices" },
  { name: "Акции", link: "/#stocks", id: "stocks" },
  { name: "Светильники", link: "/lamps" },
  { name: "Вызвать замерщика", link: "/#orderStages", id: "orderStages" },
  { name: "Калькулятор", link: "" },
];

export const listCatalog = [
  { name: "Матовый", link: "/catalog/matte" },
  { name: "Лаковый", link: "/catalog/varnish" },
  { name: "Тканевый", link: "/catalog/fabric" },
  { name: "Цветной", link: "/catalog/color" },
  { name: "Многоуровневый", link: "/catalog/multiLevel" },
];

export const listServices = [
  { name: "Замер", link: "/#orderStages", id: "orderStages" },
  { name: "Монтаж", link: "/services#installation", id: "installation" },
  { name: "Дизайн", link: "/services#design", id: "design" },
  { name: "Ремонт", link: "/services#repair", id: "repair" },
  { name: "Освещение", link: "/lamps" },
];
