import { Link, NavLink, useLocation } from "react-router-dom";
import { MailLogo } from "../../../assets/svg/mainLogo";
import { list } from "./constants/list";
import style from "./header.module.scss";
import { BurgerButton } from "../../../assets/svg/burgerButtons";
import { useEffect, useState } from "react";
import BurgerMenu from "./burgerMenu";

const Header = () => {
  const [isOpenBurger, setIsOpenBurger] = useState(false);

  const handleOpen = () => {
    setIsOpenBurger(true);
  };
  const handleClose = () => {
    setIsOpenBurger(false);
  };

  const location = useLocation();
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      scrollToElement(hash.substring(1));
    }
  }, [location]);

  const handlePhoneClick = () => {
    const phoneNumber = "+79806304688";
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
  };

  const handleEmailClick = () => {
    const email = "info@potolnat.ru";
    const mailtoLink = `mailto:${email}`;
    window.open(mailtoLink, "_blank");
  };

  return (
    <header className={style.header}>
      <div className={isOpenBurger ? style.visible : style.invisible}>
        <BurgerMenu handleClose={handleClose} />
      </div>
      <div className={style.wrapperHeader}>
        <div className={style.header__logo}>
          <Link to="/">
            <MailLogo />
          </Link>
        </div>
        <ul className={style.header__list}>
          {list.map((item, index) => {
            return item.name === "Акции" ? (
              <Link
                key={index}
                className={style.link}
                to={item.link}
                onClick={() => scrollToElement("stocks")}
              >
                {item.name}
              </Link>
            ) : (
              <NavLink key={index} className={style.link} to={item.link}>
                {item.name}
              </NavLink>
            );
          })}
          <li className={style.item}>
            <span className={style.itemBold}>8:00 – 21:00</span>
            ежедневно
          </li>
          <li className={style.item}>
            <span className={style.item__phone} onClick={handlePhoneClick}>
              +7 980 630-46-88
            </span>
            <span className={style.item__email} onClick={handleEmailClick}>
              info@potolnat.ru
            </span>
          </li>
          <div className={style.mobileBurgerBtn} onClick={handleOpen}>
            <BurgerButton />
          </div>
        </ul>
      </div>
    </header>
  );
};

export default Header;
