import Footer from "../../common/Footer";
import Header from "../../common/Header";
import PrivacyPolicy from "./PrivacyPolicy";

import style from "./privacyPolicyPage.module.scss";

const PrivacyPolicyPage = () => {
  return (
    <>
      <div className={style.wrapperHeader}>
        <Header />
      </div>
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
