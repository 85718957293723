import avatar1 from "../../../../../assets/img/reviews/avatar1.png";
import avatar2 from "../../../../../assets/img/reviews/avatar2.png";
import avatar3 from "../../../../../assets/img/reviews/avatar3.png";
import avatar4 from "../../../../../assets/img/reviews/avatar4.png";
import avatar5 from "../../../../../assets/img/reviews/avatar5.png";
import avatar6 from "../../../../../assets/img/reviews/avatar6.png";
import avatar7 from "../../../../../assets/img/reviews/avatar7.png";
import avatar8 from "../../../../../assets/img/reviews/avatar8.png";

export const dataReviews = [
  {
    name: "Людмила Андреевна",
    avatar: <img src={avatar1} alt="avatar" width="100%" height="100%"></img>,
    text: "Такие молодцы ребята, настоящие профессионалы. Приехали с каталогом, позволили выбирать долго. Я не могла определиться, каталог большой — глаза разбегаются. Все померили тщательно. И установили потолки быстро, не нарадуюсь. Всем про них рассказываю.",
  },
  {
    name: "Николай",
    avatar: <img src={avatar2} alt="avatar" width="100%" height="100%"></img>,
    text: "Заказывал многоуровневый потолок. Все сделали как надо. Цены нормальные, спецы серьёзные.",
  },
  {
    name: "Ольга",
    avatar: <img src={avatar3} alt="avatar" width="100%" height="100%"></img>,
    text: "Очень быстро и профессионально работают. Мне было важно, чтобы всё сделали по часам. У меня времени ждать нет — всё сделали, как просила. Спасибо им за это большое.",
  },
  {
    name: "Ян",
    avatar: <img src={avatar4} alt="avatar" width="100%" height="100%"></img>,
    text: "Офигенский потолок. Звёздочками светится и тёмно синий весь. Мне очень нравится! Зашибись!",
  },
  {
    name: "Александр",
    avatar: <img src={avatar5} alt="avatar" width="100%" height="100%"></img>,
    text: "У меня комната нестандартная до крайности, так получилось — много чего поменял сам. Сомневался что можно смонтировать навесной потолок такой формы. Зря сомневался. Посмотрел с мастерами что и как, поговорили. Всё сделали — результатом очень доволен, даже счастлив.",
  },
  {
    name: "Тимур",
    avatar: <img src={avatar6} alt="avatar" width="100%" height="100%"></img>,
    text: "Нужен был хороший потолок в новый дом, заказ большой. Пригласил ПотолНат, всё посчитали сразу на месте. Монтируют быстро, без помарок и мусора этого проклятого, который меня достал за ремонт, не было почти. Качественная работа, достойная.",
  },
  {
    name: "Ирина",
    avatar: <img src={avatar7} alt="avatar" width="100%" height="100%"></img>,
    text: "А мне знакомая их порекомендовала в ванной потолок сделать. Очень хорошо вышло, там потолок вечно отсыревал — а теперь нет. Прямо помогли мне.",
  },
  {
    name: "Михаил",
    avatar: <img src={avatar8} alt="avatar" width="100%" height="100%"></img>,
    text: "На фирму свою заказывал потолки без ненужных претензий на оригинальность. Думал сделаю дёшево и сердито, по минимуму — цены глянул, решил что можно и не по минимуму. Сотрудники довольны, я тоже.",
  },
];
