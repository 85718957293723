import iconOurServices1 from "../../../../../assets/img/servicesPage/iconOurServices1.jpg";
import iconOurServices2 from "../../../../../assets/img/servicesPage/iconOurServices2.jpg";
import iconOurServices3 from "../../../../../assets/img/servicesPage/iconOurServices3.jpg";

export const dataPoints = [
  {
    icon: (
      <img src={iconOurServices1} alt="icon" style={{ width: "100%" }}></img>
    ),
    title: "Гарантии",
    text: "Мы гарантируем качество и выполняем договорённости. Наша репутация очень важна для нас, поэтому ваши интересы защищены договором и нашей ответственностью.",
  },
  {
    icon: (
      <img src={iconOurServices2} alt="icon" style={{ width: "100%" }}></img>
    ),
    title: "качество",
    text: "Мы работаем только с материалами от проверенных производителей, безопасность, экологичность и высо кое качество которых можем подтвердить сертификатами.",
  },
  {
    icon: (
      <img src={iconOurServices3} alt="icon" style={{ width: "100%" }}></img>
    ),
    title: "надёжность",
    text: "Наши опытные бригады мастеров надёжно и оперативно создадут конструкции любой конфигурации, которые станут достойным украшением вашего интерьера.",
  },
];
