export const IconSquare = () => {
  return (
    <svg
      width="40"
      height="60"
      viewBox="0 0 40 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.00772595"
        y="0.696891"
        width="24.657"
        height="24.6408"
        transform="matrix(0.709366 0.704841 -0.724817 0.688941 22.1904 5.42925)"
        stroke="#37322F"
      />
      <rect
        x="-0.00772595"
        y="0.696891"
        width="24.9013"
        height="24.885"
        transform="matrix(0.709366 0.704841 -0.724817 0.688941 19.2978 5.42925)"
        fill="#FFB800"
        stroke="#FFF4DE"
      />
    </svg>
  );
};
