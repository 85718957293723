import designImg1 from "../../../../../assets/img/servicesPage/designImg1.png";
import designImg2 from "../../../../../assets/img/servicesPage/designImg2.png";
import designImg3 from "../../../../../assets/img/servicesPage/designImg3.png";
import designImg4 from "../../../../../assets/img/servicesPage/designImg4.png";
import designImg5 from "../../../../../assets/img/servicesPage/designImg5.png";
import designImg6 from "../../../../../assets/img/servicesPage/designImg6.png";

export const dataPointsDesign = [
  {
    title: "Классика",
    img: <img src={designImg1} alt="ceiling" style={{ width: "100%" }}></img>,
    text: "Подчёркивает вкус и стиль владельца и, как правило, высокий социальный статус. В основе такого дизайна преобладают светлые оттенки, в основном, мягкие белого и древесного цвета. Вроде бы всё обычно — но тонко и со вкусом.",
  },
  {
    title: "Минимализм",
    img: <img src={designImg2} alt="ceiling" style={{ width: "100%" }}></img>,
    text: "Просторный, лёгкий и светлый дизайн, создающий впечатление большого воздушного пространства и лёгкого дыхания. И потолки соответствующие — высокие и со скрытым освещением.",
  },
  {
    title: "Фьюжн",
    img: <img src={designImg3} alt="ceiling" style={{ width: "100%" }}></img>,
    text: "Здесь фантазия клиента может свободно решать, какое именно интерьерное решение ему по вкусу. Более сотни различных оттенков для вашего потолка",
  },
  {
    title: "Модерн",
    img: <img src={designImg4} alt="ceiling" style={{ width: "100%" }}></img>,
    text: "Популярными назвать эти потолки вряд ли удастся из-за их цены — самой высокой, это сложные и необычные интерьеров. Их высоко ценят любители эксклюзивного дизайна решения для особых",
  },
  {
    title: "Hi-Tech",
    img: <img src={designImg5} alt="ceiling" style={{ width: "100%" }}></img>,
    text: "Это стиль для тех, кто любит подчёркивать технологичность своего дома и стиль последних научных достижений в области быта. Такое решение сразу намекает на фразу «умный дом».",
  },
  {
    title: "Этника",
    img: <img src={designImg6} alt="ceiling" style={{ width: "100%" }}></img>,
    text: "Слово говорит само за себя — стиль с национальным характером. Мы сделаем потолок, который идеально впишется в интерьер, не нарушая дизайна ни в чем.",
  },
];
