export const dataBestSolution = [
  {
    title: "Ровный",
    text: "Самый ровный среди потолков — никакой побелки и мусора, натяжной потолок ровный с рождения и навсегда. Вам не нужно его обновлять.",
  },
  {
    title: "Минимальные требования к пространству",
    text: "Натяжные потолки очень компактны и при установке они занимают мало места.",
  },
  {
    title: "Любые форма и цвет",
    text: "Многоуровневый, текстурный, глянцевый, сатиновый, многоцветный, тканевый — в нашем каталоге найдётся всё.",
  },
  {
    title: "Эстетика",
    text: "Натяжные потолки выглядят как новые довольно долго. Конечно, лет через 15 можно будет его поменять, просто потому что захочется чего‑то новенького.",
  },
  {
    title: "Экономичность",
    text: "По цене установки и эксплуатации натяжные потолки выгоднее любого другого решения.",
  },
  {
    title: "Гигиена",
    text: "Запахи и влагу натяжной потолок не впитывает. В квартире будет свежо и комфортно.",
  },
];
