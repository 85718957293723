import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "swiper/scss";
import "swiper/scss/pagination";
import slide1 from "../../../../assets/img/infoSlider/slide1.jpg";
import slide2 from "../../../../assets/img/infoSlider/slide2.jpg";
import slide3 from "../../../../assets/img/infoSlider/slide3.jpg";
import mobileSlide1 from "../../../../assets/img/infoSlider/mobileSlide1.jpg";
import mobileSlide2 from "../../../../assets/img/infoSlider/mobileSlide2.jpg";
import mobileSlide3 from "../../../../assets/img/infoSlider/mobileSlide3.jpg";
import style from "./infoSlider.module.scss";
import ModalForm from "../../../common/ModalWindows/ModalForm";

const InfoSlider = () => {
  const [openBackCall, setOpenBackCall] = useState(false);
  const handleOpenBackCall = () => setOpenBackCall(true);
  const handleCloseBackCall = () => setOpenBackCall(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className={style.infoSlider}>
      <Swiper
        pagination={{
          clickable: true,
          bulletClass: `${style.customBullet} swiper-pagination-bullet`,
          bulletActiveClass: `${style.customBulletActive} swiper-pagination-bullet-active`,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed="1500"
        modules={[Autoplay, Pagination]}
        className={`${style.mySwiper} swiper-wrapper`}
      >
        <SwiperSlide className={`${style.mySwiperSlide} swiper-slide`}>
          <img
            className={style.slide}
            src={slide1}
            alt="discount"
            onClick={handleOpenBackCall}
          ></img>
          <img
            className={style.mobileSlide}
            src={mobileSlide1}
            alt="discount"
            onClick={handleOpenBackCall}
          ></img>
        </SwiperSlide>
        <SwiperSlide className={`${style.mySwiperSlide} swiper-slide`}>
          <img
            className={style.slide}
            src={slide2}
            alt="discount"
            onClick={handleOpenBackCall}
          ></img>
          <img
            className={style.mobileSlide}
            src={mobileSlide2}
            alt="discount"
            onClick={handleOpenBackCall}
          ></img>
        </SwiperSlide>
        <SwiperSlide className={`${style.mySwiperSlide} swiper-slide`}>
          <img
            className={style.slide}
            src={slide3}
            alt="discount"
            onClick={handleOpenBackCall}
          ></img>
          <img
            className={style.mobileSlide}
            src={mobileSlide3}
            alt="discount"
            onClick={handleOpenBackCall}
          ></img>
        </SwiperSlide>
      </Swiper>
      <div className={`my-custom-pagination ${style.customPagination}`}></div>
      <Modal open={openBackCall} onClose={handleCloseBackCall}>
        <Box sx={styleBox}>
          <ModalForm
            title="Хочу скидку / подарок"
            subtitle="Для получения заполните и отправьте заявку"
            handleClose={handleCloseBackCall}
          />
        </Box>
      </Modal>
    </section>
  );
};

export default InfoSlider;
