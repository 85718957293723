import {
  Lighting,
  Square,
  Technology,
  Texture,
} from "../../../assets/svg/cardOurWorksSvg";
import style from "./cardOurWorks.module.scss";

const CardOurWorks = ({ data }) => {
  return (
    <div className={style.cardOurWorks}>
      {data.map((item, index) => {
        return (
          <>
            <div className={style.cardOurWorks__img}>{item.img}</div>
            <div className={style.wrapper}>
              <h2 className={style.cardOurWorks__title}>{item.title}</h2>
              <p className={style.cardOurWorks__address}>{item.address}</p>
              <div className={style.cardOurWorks__wrapperInfo}>
                <div className={style.info}>
                  <div className={style.svg}>
                    <Square />
                  </div>
                  <p className={style.text}>{`Площадь ${item.square}`}</p>
                </div>
                <div className={style.info}>
                  <div className={style.svg}>
                    <Lighting />
                  </div>
                  <p className={style.text}>{`Освещение: ${item.lighting}`}</p>
                </div>
                <div className={style.info}>
                  <div className={style.svg}>
                    <Texture />
                  </div>
                  <p className={style.text}>{`Фактура: ${item.texture}`}</p>
                </div>
                <div className={style.info}>
                  <div className={style.svg}>
                    <Technology />
                  </div>
                  <p
                    className={style.text}
                  >{`Технология: ${item.technology}`}</p>
                </div>
              </div>
              <p
                className={style.cardOurWorks__price}
              >{`Цена: ${item.price} ₽`}</p>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default CardOurWorks;
