import CustomBreadcrumbs from "../../common/CustomBreadcrumbs";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Design from "./Design";
import Installation from "./Installation";
import OurServices from "./OurServices";
import Repair from "./Repair";
import WorkWithUs from "./WorkWithUs";

import style from "./servicesPage.module.scss";

const ServicesPage = () => {
  return (
    <>
      <div className={style.wrapperHeader}>
        <Header />
      </div>
      <CustomBreadcrumbs currentPage="Наши услуги" />
      <OurServices />
      <WorkWithUs />
      <div id="installation" className={style.wrapperInstallation}>
        <Installation />
      </div>
      <div id="design" className={style.wrapperDesign}>
        <Design />
      </div>
      <div id="repair" className={style.wrapperRepair}>
        <Repair />
      </div>

      <Footer />
    </>
  );
};

export default ServicesPage;
