import { Link, NavLink } from "react-router-dom";
import style from "./footer.module.scss";
import { listCatalog, listOurWorks, listServices } from "./constants/dataLists";
import { FooterLogo } from "../../../assets/svg/footerLogo";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Сalculator from "../ModalWindows/Сalculator";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [openСalculator, setOpenСalculator] = useState(false);
  const handleOpenСalculator = () => setOpenСalculator(true);
  const handleCloseСalculator = () => setOpenСalculator(false);
  const styleBoxCalculator = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const handlePhoneClick = () => {
    const phoneNumber = "+79806304688";
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
  };

  const handleEmailClick = () => {
    const email = "info@potolnat.ru";
    const mailtoLink = `mailto:${email}`;
    window.open(mailtoLink, "_blank");
  };

  return (
    <footer className={style.footer}>
      <div className={style.wrapperFooter}>
        <div className={style.logo}>
          <Link to="/" onClick={scrollToTop}>
            <FooterLogo />
          </Link>
        </div>
        <ul className={style.ourWorksBlock}>
          <NavLink className={style.title} to="/#ourWorks">
            Наши работы
          </NavLink>
          <>
            {listOurWorks.map((item, index) => {
              return item.name === "Калькулятор" ? (
                <span
                  className={style.ourWorksBlock__subtitle}
                  key={index}
                  onClick={handleOpenСalculator}
                >
                  {item.name}
                </span>
              ) : item.name === "Светильники" ? (
                <Link
                  key={index}
                  className={style.ourWorksBlock__subtitle}
                  to={item.link}
                  onClick={scrollToTop}
                >
                  {item.name}
                </Link>
              ) : (
                <Link
                  key={index}
                  className={style.ourWorksBlock__subtitle}
                  to={item.link}
                >
                  {item.name}
                </Link>
              );
            })}
          </>
          <Modal open={openСalculator} onClose={handleCloseСalculator}>
            <Box sx={styleBoxCalculator}>
              <Сalculator handleClose={handleCloseСalculator} />
            </Box>
          </Modal>
        </ul>

        <ul className={style.catalogBlock}>
          <NavLink className={style.title} to="/catalog/matte">
            Каталог
          </NavLink>
          {listCatalog.map((item, index) => {
            return (
              <NavLink
                className={style.catalogBlock__subtitle}
                key={index}
                to={item.link}
              >
                {item.name}
              </NavLink>
            );
          })}
        </ul>
        <ul className={style.servicesBlock}>
          <NavLink className={style.title} to="/services">
            Услуги
          </NavLink>
          {listServices.map((item, index) => {
            return item.name === "Освещение" ? (
              <Link
                className={style.servicesBlock__subtitle}
                key={index}
                to={item.link}
                onClick={scrollToTop}
              >
                {item.name}
              </Link>
            ) : (
              <Link
                className={style.servicesBlock__subtitle}
                key={index}
                to={item.link}
              >
                {item.name}
              </Link>
            );
          })}
        </ul>
        <div className={style.wrapperPrivacyPolicy}>
          <p className={style.privacyPolicy}>
            © 2023 Компания ПотолНат
            <br />
            <NavLink className={style.privacyPolicy__link} to="/privacyPolicy">
              Политика конфиденциальности
            </NavLink>
          </p>
        </div>
        <div className={style.wrapperTime}>
          <p className={style.wrapperTime__text}>
            Работаем в Твери <br /> и Тверской области
          </p>
          <p className={style.wrapperTime__text}>
            <span className={style.bold}>8:00–21:00</span>, <br />
            ежедневно
          </p>
        </div>
        <div className={style.wrapperNumber}>
          <p className={style.wrapperNumber__text}>Свяжитесь с нами</p>
          <p
            className={style.wrapperNumber__textUnderline}
            onClick={handlePhoneClick}
          >
            +7 980 630-46-88
          </p>
          <p
            className={style.wrapperNumber__textUnderline}
            onClick={handleEmailClick}
          >
            info@potolnat.ru
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
