export const BurgerButton = () => {
  return (
    <svg
      width="49"
      height="40"
      viewBox="0 0 49 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="49" height="4.00008" fill="#F5B000" />
      <rect y="18" width="49" height="4.00008" fill="#F5B000" />
      <rect y="36" width="49" height="4.00008" fill="#F5B000" />
    </svg>
  );
};

export const BurgerButtonClose = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5668 22.9826L31.1127 32.5286L32.1734 31.4679L22.6274 21.922L32.8805 11.6689L31.8198 10.6082L21.5668 20.8613L10.9602 10.2547L9.89949 11.3153L20.5061 21.922L10.6066 31.8214L11.6673 32.8821L21.5668 22.9826Z"
        fill="#37322F"
      />
    </svg>
  );
};
