import Button from "../../../common/Buttons/button";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import style from "./photoCeilings.module.scss";
import { useEffect, useState } from "react";

const PhotoCeilings = ({ dataPhotoCeilings }) => {
  const [slidesPerView, setSlidesPerView] = useState(1.6);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 560) {
        setSlidesPerView(1);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [slidesPerView]);
  return (
    <div className={style.photoCeilings}>
      <h3 className={style.photoCeilings__title}>
        {`Фото ${dataPhotoCeilings.title} натяжных потолков`}
      </h3>
      <div className={style.photoCeilings__wrapperBlocks}>
        {dataPhotoCeilings.blocks.map((block, index) => {
          return (
            <div className={style.wrapperTextAndImg} key={index}>
              <div className={style.img}>{block.img}</div>
              <p className={style.text}>{block.text}</p>
            </div>
          );
        })}
      </div>
      <div className={style.photoCeilings__wrapperBlocksMobile}>
        <Swiper
          pagination={{
            clickable: true,
            bulletClass: `${style.customBullet} swiper-pagination-bullet`,
            bulletActiveClass: `${style.customBulletActive} swiper-pagination-bullet-active`,
          }}
          speed="1500"
          slidesPerView={slidesPerView}
          spaceBetween={20}
          style={{ padding: "5px", alignItems: "center" }}
          modules={[Pagination]}
        >
          {dataPhotoCeilings.blocks.map((slide, index) => {
            return (
              <SwiperSlide
                className={`${style.mySwiperSlideMobile} swiper-slide`}
                key={index}
              >
                <div className={style.wrapperTextAndImgMobile} key={index}>
                  <div className={style.img}>{slide.img}</div>
                  <p className={style.text}>{slide.text}</p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <Link className={style.photoCeilings__button} to="/prices">
        <Button text="К таблице цен" underline="black" />
      </Link>
    </div>
  );
};

export default PhotoCeilings;
