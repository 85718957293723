export const Rhombus = ({ color }) => {
  return (
    <svg
      width="50"
      height="72"
      viewBox="0 0 50 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.00754723"
        y="0.682133"
        width="30.4404"
        height="30.449"
        transform="matrix(0.723597 0.690222 -0.738692 0.674043 27.6112 6.47658)"
        stroke="#37322F"
      />
      <rect
        x="-0.0075472"
        y="0.682133"
        width="30.7399"
        height="30.7485"
        transform="matrix(0.723597 0.690222 -0.738692 0.674043 23.9959 6.4756)"
        fill={color}
        stroke="#FFF4DE"
      />
    </svg>
  );
};
