import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import style from "./customBreadcrumbs.module.scss";

const CustomBreadcrumbs = ({ currentPage }) => {
  return (
    <div className={style.customBreadcrumbs}>
      <Breadcrumbs
        className={style.breadcrumbs}
        separator={<span className={style.customSeparator}>/</span>}
      >
        <Link className={style.linkMain} underline="none" href="/">
          Главная
        </Link>
        <Typography className={style.currentPage}>{currentPage}</Typography>
      </Breadcrumbs>
    </div>
  );
};

export default CustomBreadcrumbs;
