import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ModalForm from "../../../common/ModalWindows/ModalForm";
import { Rhombus } from "../../../../assets/svg/iconRhombusInPrice";
import style from "./tablePrice.module.scss";
import Button from "../../../common/Buttons/button";

const TablePrice = ({ colorRhombus, title, dataTable }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <div className={style.tablePrice}>
      <div className={style.tablePrice__wrapperTitle}>
        <div className={style.icon}>
          <Rhombus color={colorRhombus} />
        </div>
        <h1 className={style.title}>{title}</h1>
      </div>
      <div className={style.tablePrice__wrapperTable}>
        <TableContainer component={Paper} elevation={0}>
          <Table className={style.table}>
            <TableHead>
              <TableRow>
                <TableCell className={style.headText} align="center">
                  Цена за м², ₽
                </TableCell>
                <TableCell className={style.headText} align="center">
                  от 30 м²
                </TableCell>
                <TableCell className={style.headText} align="center">
                  от 15 м²
                </TableCell>
                <TableCell className={style.headText} align="center">
                  от 5 м²
                </TableCell>
                <TableCell className={style.headText} align="center">
                  Базовая
                </TableCell>
                <TableCell className={style.headText} align="center">
                  Производство
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row, index) => {
                return (
                  <TableRow
                    sx={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: "#FFFBF2",
                      },
                    }}
                    key={index}
                  >
                    <TableCell
                      className={style.bodyCell}
                      align="start"
                      sx={{ maxWidth: "260px" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell className={style.bodyCell} align="center">
                      {row.price30}
                    </TableCell>
                    <TableCell className={style.bodyCell} align="center">
                      {row.price15}
                    </TableCell>
                    <TableCell className={style.bodyCell} align="center">
                      {row.price5}
                    </TableCell>
                    <TableCell className={style.bodyCell} align="center">
                      {row.priceBase}
                    </TableCell>
                    <TableCell className={style.bodyCell} align="center">
                      {row.manufacturer}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={style.button} onClick={handleOpen}>
          <Button text="Заказать" underline="black" />
        </div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={styleBox}>
            <ModalForm
              title="обратный звонок"
              subtitle="Вы можете задать вопрос или получить консультацию, заказав обратный звонок"
              handleClose={handleClose}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default TablePrice;
