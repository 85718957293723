import TablePrice from "../TablePrice";
import { dataTables } from "../constants/dataTables";
import style from "./tables.module.scss";

const Tables = () => {
  return (
    <section className={style.tables}>
      {dataTables.map((table, index) => {
        return (
          <TablePrice
            key={index}
            colorRhombus={table.colorRhombus}
            title={table.title}
            dataTable={table.dataTable}
          />
        );
      })}
    </section>
  );
};

export default Tables;
