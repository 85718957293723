import { NavLink } from "react-router-dom";
import { BurgerButtonClose } from "../../../assets/svg/burgerButtons";
import style from "./header.module.scss";
import { list } from "./constants/list";

const BurgerMenu = ({ handleClose }) => {
  return (
    <div className={style.burgerMenu}>
      <ul className={style.burgerMenu__list}>
        {list.map((item, index) => {
          return (
            <NavLink
              key={index}
              className={style.burgerMenu__item}
              to={item.link}
            >
              {item.name}
            </NavLink>
          );
        })}
      </ul>
      <div className={style.burgerMenu__btnClose} onClick={handleClose}>
        <BurgerButtonClose />
      </div>
    </div>
  );
};

export default BurgerMenu;
