import CustomBreadcrumbs from "../../common/CustomBreadcrumbs";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Tables from "./Tables";
import style from "./pricePage.module.scss";

const PricePage = () => {
  return (
    <>
      <div className={style.wrapperHeader}>
        <Header />
      </div>
      <CustomBreadcrumbs currentPage="Цены" />
      <Tables />
      <Footer />
    </>
  );
};

export default PricePage;
