import linesImg from "../../../../../assets/img/matteCardsImg/lines.jpg";
import windowImg from "../../../../../assets/img/matteCardsImg/window.jpg";
import pointImg from "../../../../../assets/img/matteCardsImg/point.jpg";
import varnishImg1 from "../../../../../assets/img/matteCardsImg/varnishImg1.jpg";
import varnishImg2 from "../../../../../assets/img/matteCardsImg/varnishImg2.jpg";
import varnishImg3 from "../../../../../assets/img/matteCardsImg/varnishImg3.jpg";
import fabricImg1 from "../../../../../assets/img/matteCardsImg/fabricImg1.jpg";
import fabricImg2 from "../../../../../assets/img/matteCardsImg/fabricImg2.jpg";
import fabricImg3 from "../../../../../assets/img/matteCardsImg/fabricImg3.jpg";
import colorImg1 from "../../../../../assets/img/matteCardsImg/colorImg1.jpg";
import colorImg2 from "../../../../../assets/img/matteCardsImg/colorImg2.jpg";
import colorImg3 from "../../../../../assets/img/matteCardsImg/colorImg3.jpg";
import multiLevelImg1 from "../../../../../assets/img/matteCardsImg/multiLevelImg1.jpg";
import multiLevelImg2 from "../../../../../assets/img/matteCardsImg/multiLevelImg2.jpg";
import multiLevelImg3 from "../../../../../assets/img/matteCardsImg/multiLevelImg3.jpg";

export const dataCardsMatte = [
  [
    {
      img: <img src={linesImg} alt="suspended ceiling" width="100%"></img>,
      title: "Натяжной потолок со световыми линиями в холле",
      address: "г. Тверь, ул. Чехова",
      square: "26м²",
      lighting: "световые линии",
      texture: "матовый",
      technology: "одноуровневый",
      price: "22 500",
    },
  ],
  [
    {
      img: <img src={windowImg} alt="suspended ceiling" width="100%"></img>,
      title: "Матовый натяжной потолок со световым окном в гостиной",
      address: "г. Тверь, ул. Спартака",
      square: "14м²",
      lighting: "точечные светильники",
      texture: "матовый",
      technology: "одноуровневый",
      price: "33 500",
    },
  ],
  [
    {
      img: <img src={pointImg} alt="suspended ceiling" width="100%"></img>,
      title: "Натяжной потолок с точечными светильниками",
      address: "г. Тверь, ул. Советская",
      square: "16м²",
      lighting: "точечные светильники",
      texture: "матовый",
      technology: "одноуровневый",
      price: "19 300",
    },
  ],
];

export const dataCardsVarnish = [
  [
    {
      img: <img src={varnishImg1} alt="suspended ceiling" width="100%"></img>,
      title: "Лаковый потолок с точечными светильниками в кухне",
      address: "г. Тверь, ул. Коминтерна",
      square: "23м²",
      lighting: "точечные светильники",
      texture: "лаковый",
      technology: "одноуровневый",
      price: "24 500",
    },
  ],
  [
    {
      img: <img src={varnishImg2} alt="suspended ceiling" width="100%"></img>,
      title: "Лаковый потолок с точечными светильниками в гостиной",
      address: "г. Тверь, пр-т Победы",
      square: "19м²",
      lighting: "точечные светильники",
      texture: "лаковый",
      technology: "одноуровневый",
      price: "21 300",
    },
  ],
  [
    {
      img: <img src={varnishImg3} alt="suspended ceiling" width="100%"></img>,
      title: "Лаковый со световыми линиями в гостиной",
      address: "г. Тверь, ул. Московская",
      square: "28м²",
      lighting: "световые линии",
      texture: "лаковый",
      technology: "одноуровневый",
      price: "35 000",
    },
  ],
];

export const dataCardsFabric = [
  [
    {
      img: <img src={fabricImg1} alt="suspended ceiling" width="100%"></img>,
      title: "Тканевый потолок с трековым освещением в спальне",
      address: "г. Тверь, ул. Дальняя",
      square: "17м²",
      lighting: "трековое освещение",
      texture: "тканевый",
      technology: "одноуровневый",
      price: "32 000",
    },
  ],
  [
    {
      img: <img src={fabricImg2} alt="suspended ceiling" width="100%"></img>,
      title: "Тканевый потолок со светодиодными панелями",
      address: "г. Тверь, ул. Казанская",
      square: "15м²",
      lighting: "светодиодные панели",
      texture: "тканевый",
      technology: "одноуровневый",
      price: "31 800",
    },
  ],
  [
    {
      img: <img src={fabricImg3} alt="suspended ceiling" width="100%"></img>,
      title: "Тканевый потолок с люстрой с крючковым креплением в гостиной",
      address: "г. Тверь, ул. 15 лет Октября",
      square: "34м²",
      lighting: "люстра",
      texture: "тканевый",
      technology: "одноуровневый",
      price: "45 400",
    },
  ],
];

export const dataCardsColor = [
  [
    {
      img: <img src={colorImg1} alt="suspended ceiling" width="100%"></img>,
      title: "Цветной потолок с точечным освещением в кухне",
      address: "г. Тверь, ул. Бакунина",
      square: "16м²",
      lighting: "точечное освещение",
      texture: "лаковый",
      technology: "одноуровневый",
      price: "17 000",
    },
  ],
  [
    {
      img: <img src={colorImg2} alt="suspended ceiling" width="100%"></img>,
      title: "Цветной потолок с точечным освещением в спальне",
      address: "г. Тверь, ул. Загородная",
      square: "20м²",
      lighting: "точечное освещение",
      texture: "лаковый",
      technology: "одноуровневый",
      price: "26 500",
    },
  ],
  [
    {
      img: <img src={colorImg3} alt="suspended ceiling" width="100%"></img>,
      title: "Цветной потолок с точечным освещением на балконе",
      address: "г. Тверь, ул. Фадеева",
      square: "6 м²",
      lighting: "точечное освещение",
      texture: "лаковый",
      technology: "одноуровневый",
      price: "11 300",
    },
  ],
];

export const dataCardsMultiLevel = [
  [
    {
      img: (
        <img src={multiLevelImg1} alt="suspended ceiling" width="100%"></img>
      ),
      title: "Многоуровневый потолок с точечным освещением в гостиной",
      address: "г. Тверь, ул. Фадеева",
      square: "41м²",
      lighting: "точечное освещение",
      texture: "цветной",
      technology: "многоуровневый",
      price: "51 000",
    },
  ],
  [
    {
      img: (
        <img src={multiLevelImg2} alt="suspended ceiling" width="100%"></img>
      ),
      title: "Многоуровневый потолок с точечным освещением в кабинете",
      address: "г. Тверь, ул. Ударная",
      square: "9м²",
      lighting: "точечное освещение",
      texture: "лаковый",
      technology: "многоуровневый",
      price: "31 500",
    },
  ],
  [
    {
      img: (
        <img src={multiLevelImg3} alt="suspended ceiling" width="100%"></img>
      ),
      title: "Многоуровневый потолок с точечным освещением в гостиной",
      address: "г. Удомля, ул. Александрова",
      square: "29м²",
      lighting: "точечное освещение",
      texture: "лаковый",
      technology: "многоуровневый",
      price: "62 500",
    },
  ],
];
