import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Checkbox } from "@mui/material";
import { SvgInButton } from "../../../../assets/svg/svgInButton";
import {
  IconCheckbox,
  IconCheckboxChecked,
} from "../../../../assets/svg/iconCheckbox";
import { ClosingIcon } from "../../../../assets/svg/closingIcon";
import calculatorImg from "../../../../assets/img/modalWindows/calculatorImg.png";
import sendEmail from "../sendEmail";

import style from "./calculator.module.scss";

const Сalculator = ({ handleClose }) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Это поле обязательное для заполнения"),
    numberPhone: Yup.string().required("Это поле обязательное для заполнения"),
    checkbox: Yup.bool().oneOf(
      [true],
      "Пожалуйста,  примите наши условия, прежде чем продолжить."
    ),
  });

  const onSubmit = (values, { resetForm }) => {
    // Отправка данных на почту
    sendEmail(values);
    // Сброс формы после отправки
    resetForm();
  };
  return (
    <div className={style.calculator}>
      <div className={style.calculator__closingBtn} onClick={handleClose}>
        <ClosingIcon />
      </div>
      <div className={style.calculator__wrapperCalculator}>
        <h3 className={style.title}>калькулятор стоимости потолков</h3>
        <p className={style.subtitle}>
          Заполните форму и мы предоставим вам полный расчет стоимости проекта
        </p>
        <div className={style.wrapperFormAndImg}>
          <div className={style.wrapperForm}>
            <Formik
              initialValues={{
                square: "",
                type: "",
                lamps: "",
                name: "",
                numberPhone: "",
                checkbox: false,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form className={style.form}>
                <Field
                  className={style.input}
                  type="text"
                  name="square"
                  placeholder="Площадь помещения (м²)"
                ></Field>
                <div className={style.wrapperError}>
                  <ErrorMessage
                    name="square"
                    component="p"
                    className={style.error}
                  />
                </div>
                <Field
                  className={style.input}
                  type="text"
                  name="type"
                  placeholder="Тип полотна"
                ></Field>
                <div className={style.wrapperError}>
                  <ErrorMessage
                    name="type"
                    component="p"
                    className={style.error}
                  />
                </div>
                <Field
                  className={style.input}
                  type="text"
                  name="lamps"
                  placeholder="Светильники (шт)"
                ></Field>
                <div className={style.wrapperError}>
                  <ErrorMessage
                    name="lamps"
                    component="p"
                    className={style.error}
                  />
                </div>
                <Field
                  className={style.input}
                  type="text"
                  name="name"
                  placeholder="Имя"
                ></Field>
                <div className={style.wrapperError}>
                  <ErrorMessage
                    name="name"
                    component="p"
                    className={style.error}
                  />
                </div>
                <Field
                  className={style.input}
                  type="text"
                  name="numberPhone"
                  placeholder="Номер телефона"
                ></Field>
                <div className={style.wrapperError}>
                  <ErrorMessage
                    name="numberPhone"
                    component="p"
                    className={style.error}
                  />
                </div>
                <button className={style.button} type="submit">
                  <div className={style.wrapperTextBtn}>
                    <p className={style.buttonText}>Отправить</p>
                    <SvgInButton />
                  </div>
                </button>

                <div className={style.wrapperCheckbox}>
                  <Field name="checkbox">
                    {({ field }) => (
                      <Checkbox
                        {...field}
                        icon={<IconCheckbox />}
                        checkedIcon={<IconCheckboxChecked />}
                      />
                    )}
                  </Field>

                  <p className={style.wrapperCheckbox__text} for="checkbox-id">
                    Согласен на обработку персональных данных
                  </p>
                </div>
                <div className={style.wrapperError}>
                  <ErrorMessage
                    name="checkbox"
                    component="p"
                    className={style.error}
                  />
                </div>
              </Form>
            </Formik>
          </div>
          <img className={style.img} src={calculatorImg} alt="calculator"></img>
        </div>
      </div>
    </div>
  );
};

export default Сalculator;
