import { dataPoints } from "./constants/dataPoints";
import img1 from "../../../../assets/img/servicesPage/img1.png";
import img2 from "../../../../assets/img/servicesPage/img2.png";
import style from "./ourServices.module.scss";

const OurServices = () => {
  return (
    <section className={style.ourServices}>
      <div className={style.wrapperOurServices}>
        <h1 className={style.wrapperOurServices__title}>наши услуги</h1>
        <p className={style.wrapperOurServices__subtitle}>
          Компания ПотолНат предлагает обширный спектр услуг по подбору,
          установке, ремонту натяжных потолков.
        </p>
        <div className={style.wrapperOurServices__wrapperPoints}>
          {dataPoints.map((point, index) => {
            return (
              <div className={style.points} key={index}>
                <div className={style.icon}>{point.icon}</div>
                <div className={style.wrapperText}>
                  <h3 className={style.title}>{point.title}</h3>
                  <p className={style.text}>{point.text}</p>
                </div>
              </div>
            );
          })}
        </div>

        <p className={style.text}>
          Премиальное качество не зависит от типа проекта. Скромные заказы
          с самыми недорогими полотнами выполняются так же грамотно и аккуратно,
          как и эксклюзивные. Творческий подход и любовь к своей работе
          позволяют нашим специалистам находить нетривиальные решения для самых
          сложных помещений.
        </p>
        <p className={style.textBold}>
          Натяжные потолки Потол Нат в течение долгих лет будут
          радовать вас и ваших близких функциональностью и эстетичностью!
        </p>
      </div>
      <img className={style.img1} src={img1} alt="ceilings"></img>
      <img className={style.img2} src={img2} alt="ceilings"></img>
    </section>
  );
};

export default OurServices;
