import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dataList } from "../../MainPage/OurWorks/constants/dataList";
import style from "./catalog.module.scss";
import CeilingDescription from "../CeilingDescription";
import {
  dataCatalogColor,
  dataCatalogFabric,
  dataCatalogMatte,
  dataCatalogMultiLevel,
  dataCatalogVarnish,
} from "./constants/dataCatalog";
import PhotoCeilings from "../PhotoCeilings";
import LatestProjects from "../LatestProjects";
import FeedbackForm from "../FeedbackForm";

const Catalog = () => {
  const [selectedDataCards, setSelectedDataCards] = useState(dataCatalogMatte);
  const navigate = useNavigate();
  const { selected } = useParams();

  const dataCards = (id) => {
    switch (id) {
      case "matte":
        return dataCatalogMatte;
      case "varnish":
        return dataCatalogVarnish;
      case "fabric":
        return dataCatalogFabric;
      case "color":
        return dataCatalogColor;
      case "multiLevel":
        return dataCatalogMultiLevel;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (selected) {
      setSelectedDataCards(dataCards(selected));
    } else {
      setSelectedDataCards(dataCatalogMatte);
    }
  }, [selected]);

  const handleClick = (item) => {
    navigate(`/catalog/${item}`);
  };

  return (
    <section className={style.catalog}>
      <div className={style.wrapperTitle}>
        <h1 className={style.catalog__title}>каталог натяжных потолков</h1>
      </div>

      <div className={style.wrapperList}>
        <ul className={style.catalog__list}>
          {dataList.map((item) => {
            return (
              <li
                className={
                  selected === item.id ? style.itemSelected : style.item
                }
                key={item.id}
                onClick={() => handleClick(item.id)}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </div>
      <div className={style.wrapperСomponents}>
        <CeilingDescription
          dataCeilingDescription={selectedDataCards.ceilingDescription}
        />
        <PhotoCeilings dataPhotoCeilings={selectedDataCards.photoCeilings} />
        <LatestProjects dataLatestProjects={selectedDataCards.latestProjects} />
        <FeedbackForm />
      </div>
    </section>
  );
};

export default Catalog;
