import style from "./privacyPolicy.module.scss";

const PrivacyPolicy = () => {
  return (
    <section className={style.privacyPolicy}>
      <h1 className={style.privacyPolicy__title}>
        политика конфиденциальности
      </h1>
      <div className={style.privacyPolicy__wrapperText}>
        <p className={style.privacyPolicy__text}>
          Политика конфиденциальности <br /> Настоящая Политика
          конфиденциальности персональных данных (далее — Политика
          конфиденциальности) определяет права Пользователя сайта компании
          «ПотолНат» на сохранение конфиденциальности в отношении сбора,
          использования, хранения, передачи и защиты персональных данных.
          Политика конфиденциальности применима к веб-сайту «potolnat.ru»,
          расположенному на доменном имени https://potolnat.ru (далее — сайт).
          Использование Пользователем сайта означает согласие с настоящей
          Политикой конфиденциальности и условиями обработки персональных данных
          Пользователя. В случае несогласия с условиями Политики
          конфиденциальности Пользователь должен немедленно прекратить
          использование сайта.
        </p>
      </div>
      <div className={style.privacyPolicy__wrapperText}>
        <p className={style.privacyPolicy__text}>
          1. Термины <br /> 1.1. В настоящей Политике конфиденциальности
          используются следующие термины: <br />
          1.1.1. «Администрация сайта https://potolnat.ru (далее — Администрация
          сайта)» – уполномоченные сотрудники на управления сайтом, действующие
          от имени «ПотолНат», которые организуют и (или) осуществляет обработку
          персональных данных, а также определяют цели обработки персональных
          данных, состав персональных данных, подлежащих обработке, действия
          (операции), совершаемые с персональными данными. <br />
          1.1.2. «Персональные данные» – любая информация, относящаяся к прямо
          или косвенно определённому или определяемому физическому лицу
          (субъекту персональных данных). <br />
          1.1.3. «Обработка персональных данных» – любое действие (операция)
          или совокупность действий (операций), совершаемых с использованием
          средств автоматизации или без использования таких средств
          с персональными данными, включая сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление, уничтожение персональных
          данных. <br />
          1.1.4. «Пользователь сайта https://potolnat.ru (далее — Пользователь)»
          – лицо, обладающее полной право — и дееспособностью, резидент
          или нерезидент Российской Федерации, давшее согласие на Обработку
          персональных данных Администрацией сайта. <br />
          1.1.5. «Cookie» – фрагмент текстовых данных, отправленный веб-сервером
          сайта и хранимый на компьютере Пользователя. При каждом последующем
          посещении сайта браузер Пользователя считывает информацию из cookie
          файла и передаёт её веб-серверу сайта. <br />
          1.1.6. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети,
          построенной по протоколу IP.
        </p>
      </div>
      <div className={style.privacyPolicy__wrapperText}>
        <p className={style.privacyPolicy__text}>
          2. В рамках настоящей Политики под «персональными данными» понимаются:
          <br />
          2.1. Данные предоставленные Пользователем самостоятельно
          при пользовании сайтом:
          <li className={style.privacyPolicy__li}>имя</li>
          <li className={style.privacyPolicy__li}>фамилия</li>
          <li className={style.privacyPolicy__li}>номер мобильного телефона</li>
          <li className={style.privacyPolicy__li}>адрес электронной почты</li>
          <li className={style.privacyPolicy__li}>номер договора</li>
          2.2. Данные, которые автоматически передаются Сервисам в процессе
          их использования с помощью установленного на устройстве Пользователя
          программного обеспечения, в том числе IP-адрес, информация из cookie,
          информация о браузере пользователя (или иной программе, с помощью
          которой осуществляется доступ к Сервисам), время доступа, адрес
          запрашиваемой страницы.
        </p>
      </div>
      <div className={style.privacyPolicy__wrapperText}>
        <p className={style.privacyPolicy__text}>
          3. Цели сбора, обработки и хранения информации предоставляемой
          пользователями сайта:
          <br />
          3.1. Обработка персональных данных Пользователя осуществляется
          в соответствии с законодательством Российской Федерации <br />
          3.2. Персональные данные Пользователя Администрация сайта может
          использовать в целях:
          <li className={style.privacyPolicy__li}>
            установления с Пользователем обратной связи, включая направление
            уведомлений, запросов, оказания услуг, обработку запросов и заявок
            от Пользователя;
          </li>
          <li className={style.privacyPolicy__li}>
            определения места нахождения Пользователя для анализа эффективности
            рекламных кампаний;
          </li>
          <li className={style.privacyPolicy__li}>
            подтверждения достоверности и полноты персональных данных,
            предоставленных Пользователем;
          </li>
          <li className={style.privacyPolicy__li}>
            осуществления рекламной деятельности с согласия Пользователя.
          </li>
        </p>
      </div>
      <div className={style.privacyPolicy__wrapperText}>
        <p className={style.privacyPolicy__text}>
          4. Условия обработки персональной информации предоставленной
          Пользователем и её передачи третьим лицам: <br />
          4.1. Администрация сайта принимает все необходимые меры для защиты
          персональных данных Пользователя от неправомерного доступа, изменения,
          раскрытия или уничтожения. <br />
          4.2. Администрация сайта предоставляет доступ к персональным данным
          Пользователя только тем работникам, подрядчикам, которым
          эта информация необходима для обеспечения функционирования сайта,
          Сервисов и оказания Услуг Пользователю. <br />
          4.3. Администрация сайта вправе использовать предоставленную
          Пользователем информацию, в том числе персональные данные, в целях
          обеспечения соблюдения требований действующего законодательства
          Российской Федерации (в том числе в целях предупреждения и/или
          пресечения незаконных и/или противоправных действий Пользователей).
          Раскрытие предоставленной Пользователем информации может быть
          произведено лишь в соответствии с действующим законодательством
          Российской Федерации по требованию суда, правоохранительных органов,
          а равно в иных предусмотренных законодательством Российской Федерации
          случаях. <br />
          4.4. Администрация сайта не проверяет достоверность информации
          предоставляемой Пользователем и исходит из того, что Пользователь
          в рамках добросовестности предоставляет достоверную и достаточную
          информацию, заботится о своевременности внесения изменений в ранее
          предоставленную информацию при появлении такой необходимости.
        </p>
      </div>
      <div className={style.privacyPolicy__wrapperText}>
        <p className={style.privacyPolicy__text}>
          5. Пользователь обязан: <br />
          5.1. Предоставить информацию о персональных данных, необходимую
          для пользования сайтом. <br />
          5.2. Обновить, дополнить предоставленную информацию о персональных
          данных в случае изменения данной информации.
        </p>
      </div>
      <div className={style.privacyPolicy__wrapperText}>
        <p className={style.privacyPolicy__text}>
          6. Администрация сайта обязана: <br />
          6.1. Использовать полученную информацию исключительно в целях,
          указанных в п. 3 настоящей Политики конфиденциальности. <br />
          6.2. Обеспечить хранение конфиденциальной информации в тайне,
          не разглашать без предварительного письменного разрешения
          Пользователя, а также не осуществлять продажу, обмен, опубликование,
          либо разглашение иными возможными способами переданных персональных
          данных Пользователя. <br />
          6.3. Принимать меры предосторожности для защиты конфиденциальности
          персональных данных Пользователя согласно порядку, обычно
          используемого для защиты такого рода информации в существующем деловом
          обороте. <br />
          6.4. Осуществить блокирование персональных данных, относящихся
          к соответствующему Пользователю, с момента обращения или запроса
          Пользователя или его законного представителя либо уполномоченного
          органа по защите прав субъектов персональных данных на период
          проверки, в случае выявления недостоверных персональных данных
          или неправомерных действий.
        </p>
      </div>
      <div className={style.privacyPolicy__wrapperText}>
        <p className={style.privacyPolicy__text}>
          7. Изменение Политики конфиденциальности. <br />
          7.1. Администрация сайта имеет право вносить изменения в настоящую
          Политику конфиденциальности. При внесении изменений в актуальной
          редакции указывается дата последнего обновления. <br />
          7.2. Новая редакция Политики вступает в силу с момента её размещения,
          если иное не предусмотрено новой редакцией Политики.
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;

<div className={style.privacyPolicy__wrapperText}>
  <p className={style.privacyPolicy__text}></p>
</div>;
