import matte from "../../../../../assets/img/ceilingTypesImg/matte.png";
import varnish from "../../../../../assets/img/ceilingTypesImg/varnish.png";
import color from "../../../../../assets/img/ceilingTypesImg/color.png";
import multi_level from "../../../../../assets/img/ceilingTypesImg/multi-level.png";
import fabric from "../../../../../assets/img/ceilingTypesImg/fabric.png";

export const dataBlocks = [
  {
    img: <img src={matte} alt="matte"></img>,
    title: "Матовые белые",
    text: "Классический потолок, внешне мало отличимый от любого другого — по данной причине многие его выбирают. Просто, привычно, доступно по цене. Лидер продаж среди всех видов натяжных потолков.",
  },
  {
    img: <img src={varnish} alt="varnish"></img>,
    title: "Лаковые белые",
    text: "Вторые по популярности. Эти потолки имеют отражающую поверхность. Не все находят такую особенность комфортной, но многим нравится зеркальный эффект — он визуально увеличивает пространство. Тоже вполне бюджетный вариант.",
  },
  {
    img: <img src={color} alt="color"></img>,
    title: "Цветные лаковые",
    text: "Здесь фантазия клиента может свободно решать, какое именно интерьерное решение ему по вкусу. Более сотни различных оттенков для вашего потолка ",
  },
  {
    img: <img src={multi_level} alt="multi_level"></img>,
    title: "Многоуровневые",
    text: "Популярными назвать эти потолки вряд ли удастся из-за их цены – самой высокой, это сложные и необычные решения для особых интерьеров. Их высоко ценят любители эксклюзивного дизайна.",
  },
  {
    img: <img src={fabric} alt="fabric"></img>,
    title: "Тканевые потолки",
    text: "В большинстве случаев выбор цвета этих потолков падает на белый цвет. Эти потолки называют бесшовными ввиду того что они могут покрывать бесшовное перекрытие более пяти метров. Некоторые выбирают этот тип потолка из-за экологичности на контрасте в ПВХ плёнкой — но это не совсем так, ткань тоже пропитывается определённым составом. А экологичность подтверждена сертификатами для всех типов потолков. Тканевые потолки подчёркивают предпочтения особо — они одни из самых дорогих.",
  },
];
