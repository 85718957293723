export const dataTables = [
  {
    colorRhombus: "#6DCB34",
    title: "Матовые натяжные потолки",
    dataTable: [
      {
        name: "Матовый белый ширина 200 см",
        price30: "100",
        price15: "120",
        price5: "140",
        priceBase: "160",
        manufacturer: "Россия-Италия",
      },
      {
        name: "Матовый цветной ширина 200 см",
        price30: "80",
        price15: "100",
        price5: "120",
        priceBase: "140",
        manufacturer: "Россия-Италия",
      },
      {
        name: "Матовый белый ширина 350 см",
        price30: "450",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Матовый цветной ширина 350 см",
        price30: "450",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Матовый белый ширина 325 см",
        price30: "250",
        price15: "300",
        price5: "350",
        priceBase: "400",
        manufacturer: "Германия",
      },
      {
        name: "Матовый цветной ширина 350 см",
        price30: "250",
        price15: "300",
        price5: "350",
        priceBase: "400",
        manufacturer: "Германия",
      },
    ],
  },
  {
    colorRhombus: "#FFB800",
    title: "Глянцевые натяжные потолки",
    dataTable: [
      {
        name: "Глянцевый белый ширина 200 см",
        price30: "100",
        price15: "120",
        price5: "140",
        priceBase: "160",
        manufacturer: "Россия-Италия",
      },
      {
        name: "Глянцевый цветной ширина 200 см",
        price30: "80",
        price15: "100",
        price5: "120",
        priceBase: "140",
        manufacturer: "Россия-Италия",
      },
      {
        name: "Глянцевый белый ширина 350 см",
        price30: "450",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Глянцевый цветной ширина 350 см",
        price30: "450",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Глянцевый белый ширина 325 см",
        price30: "250",
        price15: "300",
        price5: "350",
        priceBase: "400",
        manufacturer: "Германия",
      },
      {
        name: "Глянцевый цветной ширина 350 см",
        price30: "250",
        price15: "300",
        price5: "350",
        priceBase: "400",
        manufacturer: "Германия",
      },
    ],
  },
  {
    colorRhombus: "#9747FF",
    title: "сатиновые натяжные потолки",
    dataTable: [
      {
        name: "Сатиновый белый ширина 200 см",
        price30: "100",
        price15: "120",
        price5: "140",
        priceBase: "160",
        manufacturer: "Россия-Италия",
      },
      {
        name: "Сатиновый цветной ширина 200 см",
        price30: "80",
        price15: "100",
        price5: "120",
        priceBase: "140",
        manufacturer: "Россия-Италия",
      },
      {
        name: "Сатиновый белый ширина 350 см",
        price30: "450",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Сатиновый цветной ширина 350 см",
        price30: "450",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Сатиновый белый ширина 325 см",
        price30: "250",
        price15: "300",
        price5: "350",
        priceBase: "400",
        manufacturer: "Германия",
      },
      {
        name: "Сатиновый цветной ширина 350 см",
        price30: "250",
        price15: "300",
        price5: "350",
        priceBase: "400",
        manufacturer: "Германия",
      },
    ],
  },
  {
    colorRhombus: "#37322F",
    title: "тканевые натяжные потолки",
    dataTable: [
      {
        name: "Тканевый классик ширина 200 см",
        price30: "100",
        price15: "120",
        price5: "140",
        priceBase: "160",
        manufacturer: "Германия",
      },
    ],
  },
  {
    colorRhombus: "#E94343",
    title: "Декоративные натяжные потолки",
    dataTable: [
      {
        name: "Декоративный перламутр ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный металик ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
      {
        name: "Декоративная галактика ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный листочки ширина 320 см",
        price30: "400",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный кружочки ширина 320 см",
        price30: "400",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный модерн ширина 320 см",
        price30: "400",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный бабочки ширина 320 см",
        price30: "400",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный весна ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный шелк ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный кожа ширина 320 см",
        price30: "400",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Германия",
      },
      {
        name: "Декоративный вдохновение ширина 320 см",
        price30: "400",
        price15: "500",
        price5: "550",
        priceBase: "650",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный полупрозрачная ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный прозрачная ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный парча ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный шанжан ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
      {
        name: "Декоративный арт-печать ширина 320 см",
        price30: "500",
        price15: "600",
        price5: "650",
        priceBase: "750",
        manufacturer: "Франция",
      },
    ],
  },
];
