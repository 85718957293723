import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../common/Buttons/button";
import CardOurWorks from "../../../common/CardOurWorks";
import {
  dataCardsColor,
  dataCardsFabric,
  dataCardsMatte,
  dataCardsMultiLevel,
  dataCardsVarnish,
} from "./constants/dataCards";
import { dataList } from "./constants/dataList";
import style from "./ourWorks.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const OurWorks = () => {
  const [selected, setSelected] = useState("matte");
  const [selectedDataCards, setSelectedDataCards] = useState([]);

  const dataCards = (id) => {
    switch (id) {
      case "matte":
        return dataCardsMatte;
      case "varnish":
        return dataCardsVarnish;
      case "fabric":
        return dataCardsFabric;
      case "color":
        return dataCardsColor;
      case "multiLevel":
        return dataCardsMultiLevel;
      default:
        return null;
    }
  };
  useEffect(() => {
    setSelectedDataCards(dataCards(selected));
  }, [selected]);

  const handleClick = (item) => {
    setSelected(item);
  };

  const [slidesPerView, setSlidesPerView] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 570) {
        setSlidesPerView(1);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [slidesPerView]);

  return (
    <section className={style.ourWorks}>
      <div className={style.wrapperOurWorks}>
        <h1 className={style.ourWorks__title}>
          наши работы по установке натяжных потолков
        </h1>
        <ul className={style.ourWorks__list}>
          {dataList.map((item) => {
            return (
              <li
                className={
                  selected === item.id ? style.itemSelected : style.item
                }
                key={item.id}
                onClick={() => handleClick(item.id)}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
        <div className={style.ourWorks__wrapperCards}>
          {selectedDataCards.map((card, index) => {
            return <CardOurWorks data={card} key={index} />;
          })}
        </div>
        <div className={style.ourWorks__wrapperBlocksMobile}>
          <Swiper
            pagination={{
              clickable: true,
              bulletClass: `${style.customBullet} swiper-pagination-bullet`,
              bulletActiveClass: `${style.customBulletActive} swiper-pagination-bullet-active`,
            }}
            speed="1500"
            slidesPerView={slidesPerView}
            spaceBetween={20}
            style={{ padding: "5px" }}
            modules={[Pagination]}
          >
            {selectedDataCards.map((slide, index) => {
              return (
                <SwiperSlide
                  className={`${style.mySwiperSlideMobile} swiper-slide`}
                  key={index}
                >
                  <CardOurWorks data={slide} key={index} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <Link className={style.ourWorks__button} to="/catalog/matte">
          <Button text="В каталог" underline="black" />
        </Link>
      </div>
    </section>
  );
};

export default OurWorks;
