import CustomBreadcrumbs from "../../common/CustomBreadcrumbs";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import DesignExamples from "./DesignExamples";
import Lamps from "./Lamps";
import style from "./lampsPage.module.scss";

const LampsPage = () => {
  return (
    <>
      <div className={style.wrapperHeader}>
        <Header />
      </div>
      <CustomBreadcrumbs currentPage="Светильники" />
      <Lamps />
      <DesignExamples />
      <Footer />
    </>
  );
};

export default LampsPage;
