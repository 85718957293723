import installationImg from "../../../../assets/img/servicesPage/installationImg.jpg";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "../../../common/Buttons/button";
import { dataPoints } from "./constants/dataPoints";
import style from "./installation.module.scss";
import ModalForm from "../../../common/ModalWindows/ModalForm";

const Installation = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className={style.installation}>
      <h1 className={style.installation__title}>Монтаж</h1>
      <div className={style.installation__wrapper}>
        <div className={style.installation__wrapperTextAndImg}>
          <p className={style.text}>
            Долговечность потолка целиком и полностью зависит от правильно
            выполненного монтажа. Мы установим любой натяжной потолок, какой
            пожелаете. Накрутки и скрытые комиссии — не наш метод, в смете
            значатся только реально предоставляемые услуги.
          </p>
          <img
            className={style.img}
            src={installationImg}
            alt="installation"
          ></img>
        </div>
        <div className={style.installation__wrapperPointsAndText}>
          <p className={style.titleText}>Расчёт делается, исходя из:</p>
          <div className={style.wrapperPoints}>
            {dataPoints.map((point, index) => {
              return (
                <div className={style.point} key={index}>
                  <div className={style.icon}>{point.icon}</div>
                  <p className={style.text}>{point.text}</p>
                </div>
              );
            })}
          </div>
          <p className={style.text}>
            Наши специалисты делают расчёт с помощью современного оборудования.
            Использоваться будут лучшие материалы.
          </p>
          <div className={style.button} onClick={handleOpen}>
            <Button text="Оставить заявку" underline="black" />
          </div>
          <Modal open={open} onClose={handleClose}>
            <Box sx={styleBox}>
              <ModalForm
                title="обратный звонок"
                subtitle="Вы можете задать вопрос или получить консультацию, заказав обратный звонок"
                handleClose={handleClose}
              />
            </Box>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default Installation;
