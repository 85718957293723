import { useEffect, useRef } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header/index";
import BestSolution from "./BestSolution";
import CeilingTypes from "./CeilingTypes";
import Description from "./Description";
import InfoSlider from "./InfoSlider";
import OrderStages from "./OrderStages";
import OurPrices from "./OurPrices";
import OurWorks from "./OurWorks";
import Reviews from "./Reviews";
import Services from "./Services";
import UniqueSolution from "./UniqueSolution";

import style from "./mainPage.module.scss";
import { useLocation } from "react-router-dom";
import useDeviceType from "../../common/hook/useDeviceType";

const MainPage = () => {
  const { isMobile } = useDeviceType();

  const stocksRef = useRef(null);
  const ourWorksRef = useRef(null);
  const orderStagesRef = useRef(null);
  const reviewsRef = useRef(null);

  const location = useLocation();

  const scrollToElement = (elementRef) => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // если заходишь с мобильного устройства, то прокручивает страницу до нужного элемента
  useEffect(() => {
    const { hash } = location;
    const targetRef = (hash) => {
      switch (hash) {
        case "#stocks":
          return stocksRef;
        case "#ourWorks":
          return ourWorksRef;
        case "#orderStages":
          return orderStagesRef;
        case "#reviews":
          return reviewsRef;

        default:
          return null;
      }
    };

    if (targetRef(hash) && isMobile) {
      scrollToElement(targetRef(hash));
    }
  }, [location, isMobile]);

  return (
    <>
      <div className={style.wrapper}>
        <Header />
        <Description />
      </div>
      <Services />
      <div id="stocks" ref={stocksRef} className={style.wrapperStocks}>
        <InfoSlider />
      </div>
      <div id="ourWorks" ref={ourWorksRef} className={style.wrapperOurWorks}>
        <OurWorks />
      </div>

      <BestSolution />

      <div
        id="orderStages"
        ref={orderStagesRef}
        className={style.wrapperOrderStages}
      >
        <OrderStages />
      </div>

      <CeilingTypes />
      <OurPrices />
      <UniqueSolution />

      <div id="reviews" ref={reviewsRef} className={style.wrapperReviews}>
        <Reviews />
      </div>

      <Footer />
    </>
  );
};

export default MainPage;
