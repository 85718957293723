import { IconSquare } from "../../../../../assets/svg/iconSquare";

export const dataRepair = [
  {
    icon: <IconSquare />,
    title: "Устранение провисания",
    text: "Чтобы предотвратить данную проблему, можно создать дополнительную вентиляцию в помещении. Неплотность базового перекрытия приводит к забору воздуха извне, что проблему и вызывает. Установка диффузоров позволяет обеспечить необходимый уровень циркуляции воздуха, способствуя таким образом выравниванию материала.",
  },
  {
    icon: <IconSquare />,
    title: "Устранение дефектов крепёжных профилей",
    text: "Если металлический или пластиковый профиль отошёл от стены, полотно отсоединяется от багета, а затем устанавливаются распорки. Это необходимо для надёжного прилегания профилей к несущей основе. После этого ПВХ или ткань прикрепляются обратно на своё место.",
  },
  {
    icon: <IconSquare />,
    title: "Разрыв шва",
    text: "В данном случае производится снятие и замена натяжного потолка. Мы предложим вам подходящий по размеру, фактуре и цвету бесшовный материал. Поскольку профили остаются нетронуты ми,  в стоимость входит только оплата за полотно и его монтаж. Мы подберём для вас оптимальное решение, чтобы обновление потолка прошло как можно проще и в соответствии с вашими пожеланиями.",
  },
  {
    icon: <IconSquare />,
    title: "Ремонт после пореза или прокола",
    text: "Повреждённый участок может быть закрыт с помощью фальш-вытяжки или использован для установки светильника. Его также можно заштопать видимым швом и прикрыть декоративным элементом. Если повреждение находится близко к краю, то можно демонтировать часть материала и приклеить новый элемент гарпунным методом,  чтобы избежать видимого шва. Возможность устранения проблемы зависит от степени повреждения. Чтобы избежать необходимости замены всего потолка, рекомендуется немедленно заклеить повреждённый участок малярным скотчем.",
  },
  {
    icon: <IconSquare />,
    title: "Ремонт потолка после залива",
    text: "Мастера осуществляют слив воды через отверстия, расположенные под осветительными приборами. Шланг используется для сбора жидкости, которая затем сливается в подставленное ведро. Вызовите наших сотрудников вовремя, это поможет предотвратить появление сырости и плесени. После удаления воды и высыхания поверхности помещения, мы применяем тепловую пушку для нагрева. Тёплый воздух способствует быстрому восстановлению формы ПВХ полотна.",
  },
];
