export const IconCheckbox = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.875 12.5V19.7917C21.875 20.3442 21.6555 20.8741 21.2648 21.2648C20.8741 21.6555 20.3442 21.875 19.7917 21.875H5.20833C4.6558 21.875 4.12589 21.6555 3.73519 21.2648C3.34449 20.8741 3.125 20.3442 3.125 19.7917V5.20833C3.125 4.6558 3.34449 4.12589 3.73519 3.73519C4.12589 3.34449 4.6558 3.125 5.20833 3.125H16.6667"
        stroke="url(#paint0_linear_1179_10618)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1179_10618"
          x1="12.5"
          y1="3.125"
          x2="12.5"
          y2="21.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#393532" />
          <stop offset="1" stopColor="#373122" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconCheckboxChecked = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 11.5L12.5 15.5L23 5" stroke="#37322F" />
      <path
        d="M21.75 12.375V19.6667C21.75 20.2192 21.5305 20.7491 21.1398 21.1398C20.7491 21.5305 20.2192 21.75 19.6667 21.75H5.08333C4.5308 21.75 4.00089 21.5305 3.61019 21.1398C3.21949 20.7491 3 20.2192 3 19.6667V5.08333C3 4.5308 3.21949 4.00089 3.61019 3.61019C4.00089 3.21949 4.5308 3 5.08333 3H16.5417"
        stroke="url(#paint0_linear_1356_15479)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1356_15479"
          x1="12.375"
          y1="3"
          x2="12.375"
          y2="21.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#393532" />
          <stop offset="1" stopColor="#373122" />
        </linearGradient>
      </defs>
    </svg>
  );
};
