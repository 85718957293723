import { SvgInButton } from "../../../assets/svg/svgInButton";
import style from "./buttons.module.scss";

const Button = ({ text, underline }) => {
  return (
    <button
      className={
        underline === "light"
          ? style.buttonUnderlineLight
          : style.buttonUnderlineDark
      }
    >
      <div className={style.wrapperText}>
        <p className={style.buttonTextLight}>{text}</p>
        <SvgInButton />
      </div>
    </button>
  );
};
export default Button;
