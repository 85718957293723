export const dataUniqueSolution = [
  {
    title: "Цены",
    text: "Это раньше натяжной потолок был исключительно уделом богатых. Сейчас натяжные потолки — один из самых дешёвых методов отделки потолка и доступен практически всем. Но можно себе сделать и очень дорогой натяжной потолок, если хочется. Выбор на любой вкус и цвет.",
  },
  {
    title: "Уход",
    text: "Не нужен. Это не шутка, всё, что вам нужно сделать, – протереть потолок сухой салфеткой, сняв пыль с поверхности. Вы гарантированно избавлены от белых хлопьев штукатурки, падающей вам на голову. Если помещение расположено к загрязнению сильнее остальных, нужно немного чаще выполнять очистку материалов — но про пыль, копоть и плесень вы можете забыть. Совсем.",
  },
  {
    title: "Дизайн",
    text: "Вы можете реализовать буквально любое дизайнерское решение. Можно ограничиться стандартным потолком, но для некоторых это слишком скучно, и они придумывают своим потолкам новые формы: конус, волна, ступени. Выбрать и реализовать можно все, что угодно. Более того, это будет изящно и технически безупречно — в отличие от других типов потолков.",
  },
  {
    title: "Универсальность",
    text: "Натяжные потолки можно монтировать практически везде, в том числе в помещениях с повышенной влажностью. Да и учитывая свойства — в таких именно помещениях и рекомендуется. Лишь для некоторых видов потолка есть ограничение — неотапливаемые помещения.",
  },
  {
    title: "Скорость и простота",
    text: "И то, и другое — в одном флаконе. Не только самый быстрый, но и самый простой способ отделки потолка. Срок изготовления от одного до трёх дней — в зависимости от сложности заказа, а монтаж осуществляется вообще за день, строительный мусор — минимален.",
  },
  {
    title: "Долговечность",
    text: "Только гарантийный срок службы натяжного потолка — десять лет, причём потолок все эти годы будет выглядеть так же. Известны случаи, когда натяжной потолок служил десятилетиями.",
  },
  {
    title: "Польза конструкции",
    text: "Натяжной потолок имеет зазор от потолка в несколько сантиметров. Удобно для расположения любых коммуникаций — электропроводки, вытяжки, сигнализации, кабелей оптоволоконной связи.",
  },
  {
    title: "Изоляция",
    text: "Та же особенность констру кции создаёт воздушную «подушку», которая существенно приглушает звуки. Можно также установить дополнительную изоляцию любого типа в межпотолочном пространстве — от шума, влаги или тепла.",
  },
  {
    title: "Защита",
    text: "Если вас затопят забывчивые соседи — которых мы осуждаем — вы можете спокойно рассчитывать на свой натяжной потолок. Он не даст обстановке комнаты пострадать. Секрет в пропитке пенополиуританом — это делает натяжной потолок не только водонепроницаемым, но ещё и эластичным.",
  },
  {
    title: "Мода",
    text: "Сейчас натяжной потолок — это не только удобное, практичное и технологичное решение. Это норма интерьера, повсеместный выбор человека со вкусом и с головой. Трэнд, если угодно.",
  },
];

export const dataUniqueSolutionMobile = [
  [
    {
      title: "Цены",
      text: "Это раньше натяжной потолок был исключительно уделом богатых. Сейчас натяжные потолки — один из самых дешёвых методов отделки потолка и доступен практически всем. Но можно себе сделать и очень дорогой натяжной потолок, если хочется. Выбор на любой вкус и цвет.",
    },
    {
      title: "Уход",
      text: "Не нужен. Это не шутка, всё, что вам нужно сделать, – протереть потолок сухой салфеткой, сняв пыль с поверхности. Вы гарантированно избавлены от белых хлопьев штукатурки, падающей вам на голову. Если помещение расположено к загрязнению сильнее остальных, нужно немного чаще выполнять очистку материалов — но про пыль, копоть и плесень вы можете забыть. Совсем.",
    },
    {
      title: "Дизайн",
      text: "Вы можете реализовать буквально любое дизайнерское решение. Можно ограничиться стандартным потолком, но для некоторых это слишком скучно, и они придумывают своим потолкам новые формы: конус, волна, ступени. Выбрать и реализовать можно все, что угодно. Более того, это будет изящно и технически безупречно — в отличие от других типов потолков.",
    },
  ],
  [
    {
      title: "Универсальность",
      text: "Натяжные потолки можно монтировать практически везде, в том числе в помещениях с повышенной влажностью. Да и учитывая свойства — в таких именно помещениях и рекомендуется. Лишь для некоторых видов потолка есть ограничение — неотапливаемые помещения.",
    },
    {
      title: "Скорость и простота",
      text: "И то, и другое — в одном флаконе. Не только самый быстрый, но и самый простой способ отделки потолка. Срок изготовления от одного до трёх дней — в зависимости от сложности заказа, а монтаж осуществляется вообще за день, строительный мусор — минимален.",
    },
    {
      title: "Долговечность",
      text: "Только гарантийный срок службы натяжного потолка — десять лет, причём потолок все эти годы будет выглядеть так же. Известны случаи, когда натяжной потолок служил десятилетиями.",
    },
  ],
  [
    {
      title: "Польза конструкции",
      text: "Натяжной потолок имеет зазор от потолка в несколько сантиметров. Удобно для расположения любых коммуникаций — электропроводки, вытяжки, сигнализации, кабелей оптоволоконной связи.",
    },
    {
      title: "Изоляция",
      text: "Та же особенность констру кции создаёт воздушную «подушку», которая существенно приглушает звуки. Можно также установить дополнительную изоляцию любого типа в межпотолочном пространстве — от шума, влаги или тепла.",
    },
    {
      title: "Защита",
      text: "Если вас затопят забывчивые соседи — которых мы осуждаем — вы можете спокойно рассчитывать на свой натяжной потолок. Он не даст обстановке комнаты пострадать. Секрет в пропитке пенополиуританом — это делает натяжной потолок не только водонепроницаемым, но ещё и эластичным.",
    },
    {
      title: "Мода",
      text: "Сейчас натяжной потолок — это не только удобное, практичное и технологичное решение. Это норма интерьера, повсеместный выбор человека со вкусом и с головой. Трэнд, если угодно.",
    },
  ],
];
