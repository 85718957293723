import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IconSquare } from "../../../../assets/svg/iconSquare";
import Button from "../../../common/Buttons/button";
import { dataStages } from "./constants/dataStages";
import workerImg from "../../../../assets/img/worker.png";
import style from "./orderStages.module.scss";
import ModalForm from "../../../common/ModalWindows/ModalForm";

const OrderStages = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className={style.orderStages}>
      <div className={style.wrapperInfo}>
        <h1 className={style.orderStages__title}>Этапы заказа</h1>
        <p className={style.orderStages__subtitle}>С нами все просто!</p>
        <div className={style.orderStages__wrapperStages}>
          {dataStages.map((stage, index) => {
            return (
              <div className={style.wrapperStage} key={index}>
                <div className={style.svg}>
                  <IconSquare />
                </div>
                <div className={style.wrapperText}>
                  <h3 className={style.title}>{stage.title}</h3>
                  <p className={style.text}>{stage.text}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className={style.orderStages__button} onClick={handleOpen}>
          <Button text="Вызвать замерщика" underline="black" />
        </div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={styleBox}>
            <ModalForm
              title="Закажите бесплатный выезд замерщика"
              subtitle="Заполните форму для вызова замерщика"
              handleClose={handleClose}
            />
          </Box>
        </Modal>
      </div>
      <img
        className={style.orderStages__img}
        src={workerImg}
        alt="worker"
      ></img>
    </section>
  );
};

export default OrderStages;
