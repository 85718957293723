import { IconSquaresInDesign } from "../../../../assets/svg/iconSquaresInDesign";
import { dataExamples } from "./constants/dataExamples";
import style from "./designExamples.module.scss";

const DesignExamples = () => {
  return (
    <section className={style.designExamples}>
      <h1 className={style.designExamples__title}>
        Примеры дизайна освещения в интерьере
      </h1>
      <div className={style.wrapperExamples}>
        {dataExamples.map((example, index) => {
          return (
            <div className={style.example} key={index}>
              <div className={style.example__img}>{example.img}</div>
              <div className={style.example__wrapperText}>
                <h3 className={style.title}>{example.title}</h3>
                <p className={style.text}>{example.text}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className={style.designExamples__iconSquares}>
        <IconSquaresInDesign />
      </div>
    </section>
  );
};

export default DesignExamples;
