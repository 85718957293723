import emailjs from "@emailjs/browser";
import { publicKey, serviceID, templateID } from "./constants/parameters";

const sendEmail = async (values) => {
  emailjs
    .send(serviceID, templateID, values, publicKey)
    .then((response) => {
      console.log("Email sent successfully!", response.status, response.text);
    })
    .catch((error) => {
      console.error("Error sending email:", error);
    });
};

export default sendEmail;
