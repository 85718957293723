export const FooterLogo = () => {
  return (
    <svg
      width="192"
      height="195"
      viewBox="0 0 192 195"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="95.4236"
        height="95.4289"
        transform="matrix(0.721176 0.692752 -0.728501 0.685044 83.29 47.7227)"
        fill="#FFB800"
      />
      <rect
        width="19.0847"
        height="103.381"
        transform="matrix(0.721176 0.692752 -0.728501 0.685044 116.423 16)"
        fill="#FFB800"
      />
      <rect
        width="103.376"
        height="103.381"
        transform="matrix(0.721176 0.692752 -0.728501 0.685044 83.3174 22.3477)"
        fill="#37322F"
      />
      <rect
        x="-0.00366274"
        y="0.688898"
        width="102.376"
        height="102.381"
        transform="matrix(0.721176 0.692752 -0.728501 0.685044 83.8182 22.5672)"
        stroke="#FFB800"
        strokeOpacity="0.65"
      />
      <rect
        x="-0.00365654"
        y="0.687733"
        width="30.8096"
        height="30.8113"
        transform="matrix(0.721176 0.692752 -0.728501 0.685044 161.567 128.829)"
        stroke="#FFB800"
        strokeWidth="0.998309"
      />
      <path
        d="M74.0838 105V126H70.4238V117.15H64.0038V126H60.3438V105H64.0038V113.73H70.4238V105H74.0838ZM87.4523 121.32H81.1823L79.8923 126H76.1723L82.3523 105H86.2823L92.4623 126H88.7423L87.4523 121.32ZM86.5223 117.93L84.3323 109.86L82.1123 117.93H86.5223ZM105.15 108.39H100.26V126H96.6002V108.39H91.6502V105H105.15V108.39Z"
        fill="#FFF4DE"
      />
      <path
        d="M57.5056 74V95H53.8456V77.42H47.4256V95H43.7656V74H57.5056ZM71.7141 74C72.3741 74 72.9141 74.21 73.3341 74.63C73.7341 75.03 73.9341 75.54 73.9341 76.16V92.84C73.9341 93.44 73.7341 93.95 73.3341 94.37C72.9141 94.79 72.3741 95 71.7141 95H62.6541C62.0141 95 61.4841 94.79 61.0641 94.37C60.6441 93.95 60.4341 93.44 60.4341 92.84V76.16C60.4341 75.54 60.6441 75.03 61.0641 74.63C61.4841 74.21 62.0141 74 62.6541 74H71.7141ZM70.2741 77.99C70.2741 77.59 70.0841 77.39 69.7041 77.39H64.6641C64.2841 77.39 64.0941 77.59 64.0941 77.99V91.01C64.0941 91.41 64.2841 91.61 64.6641 91.61H69.7041C70.0841 91.61 70.2741 91.41 70.2741 91.01V77.99ZM89.8611 77.39H84.9711V95H81.3111V77.39H76.3611V74H89.8611V77.39ZM103.589 74C104.249 74 104.789 74.21 105.209 74.63C105.609 75.03 105.809 75.54 105.809 76.16V92.84C105.809 93.44 105.609 93.95 105.209 94.37C104.789 94.79 104.249 95 103.589 95H94.5291C93.8891 95 93.3591 94.79 92.9391 94.37C92.5191 93.95 92.3091 93.44 92.3091 92.84V76.16C92.3091 75.54 92.5191 75.03 92.9391 74.63C93.3591 74.21 93.8891 74 94.5291 74H103.589ZM102.149 77.99C102.149 77.59 101.959 77.39 101.579 77.39H96.5391C96.1591 77.39 95.9691 77.59 95.9691 77.99V91.01C95.9691 91.41 96.1591 91.61 96.5391 91.61H101.579C101.959 91.61 102.149 91.41 102.149 91.01V77.99ZM107.846 95L114.026 74H117.956L124.136 95H120.416L115.976 78.86L111.566 95H107.846Z"
        fill="#FFF4DE"
      />
    </svg>
  );
};
