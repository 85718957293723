import lampImg1 from "../../../../../assets/img/designExamples/lamp1.jpg";
import lampImg2 from "../../../../../assets/img/designExamples/lamp2.jpg";
import lampImg3 from "../../../../../assets/img/designExamples/lamp3.jpg";
import lampImg4 from "../../../../../assets/img/designExamples/lamp4.jpg";
import lampImg5 from "../../../../../assets/img/designExamples/lamp5.jpg";
import lampImg6 from "../../../../../assets/img/designExamples/lamp6.jpg";
import lampImg7 from "../../../../../assets/img/designExamples/lamp7.jpg";

export const dataExamples = [
  {
    img: <img src={lampImg1} alt="lamp" width="100%"></img>,
    title: "Люстры с крючковым креплением",
    text: "Стандартное и обычное. Крюк закладывается во время строительства дома: калибровка по высоте невозможна. Однако это нивелируется регулировочным стаканом у основания люстры. Люстры с потолочным креплением. Монтаж производится на пластиковую платформу, закрепляется металлическими пластинами. Осветительный прибор прикручивается к платформе через полотно – в итоге сидит, как влитая.",
  },
  {
    img: <img src={lampImg2} alt="lamp" width="100%"></img>,
    title: "Точечное освещение",
    text: "Несколько небольших светильников – количество зависит от ваших предпочтении или требований к освещению помещения – установленных по всему потолку и либо утопленных в него полностью, либо выступающих, которые встраиваются в декоративный корпус.",
  },
  {
    img: <img src={lampImg3} alt="lamp" width="100%"></img>,
    title: "Звездное небо",
    text: "Очень красивый эффект, имитация звёздного неба с помощью светодиодов с хрустальными рассеивателями.",
  },
  {
    img: <img src={lampImg4} alt="lamp" width="100%"></img>,
    title: "Светодиодная лента",
    text: "Прокладывается по периметру натяжного потолка и может моргать, перемигиваться и играть огнями как вам захочется. А может просто ровно светить – если вам это всё не нужно. Режимы переключаются.",
  },
  {
    img: <img src={lampImg5} alt="lamp" width="100%"></img>,
    title: "Светодиодные Панели",
    text: "Для эффекта мягкого света используется LED панель, в которой насчитывается порядка 100 светодиодов.",
  },
  {
    img: <img src={lampImg6} alt="lamp" width="100%"></img>,
    title: "Трековое освещение",
    text: "Этот вариант концентрирует свет на определённой области помещения и позволяет быстро изменить освещение, чтобы осветить любую его часть.",
  },
  {
    img: <img src={lampImg7} alt="lamp" width="100%"></img>,
    title: "Световые линии",
    text: "Световые линии монтируются открыто или скрыты за полотном. Одно из самых изящных и незаметных внешне решений – в случае, если линии скрыты. Тогда они видны только при включённом свете.",
  },
];
