import {
  GreenRhombus,
  RedRhombus,
  YellowRhombus,
} from "../../../../../assets/svg/ourPricesIcons";

export const dataOurPrices = [
  {
    icon: <GreenRhombus />,
    title: "Эконом",
    price: "80 ",
    width: "2.0 м.",
    production: "Россия-Италия",
    guarantee: "15 лет",
  },
  {
    icon: <YellowRhombus />,
    title: "Стандарт",
    price: "250 ",
    width: "3.5 м.",
    production: "Германия",
    guarantee: "15 лет",
  },
  {
    icon: <RedRhombus />,
    title: "Декоративные",
    price: "400 ",
    width: "3.2 м.",
    production: "Франция",
    guarantee: "15 лет",
  },
];
