export const SvgInButton = () => {
  return (
    <svg
      width="57"
      height="33"
      viewBox="0 0 57 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.00468902"
        y="0.880805"
        width="16.4926"
        height="16.4853"
        transform="matrix(0.705789 0.708422 -0.713292 0.700867 20.8964 4.26289)"
        stroke="#37322F"
        strokeWidth="1.25"
      />
      <path
        d="M49.3123 17.3638C49.5563 17.1197 49.5563 16.724 49.3123 16.4799L45.3348 12.5025C45.0907 12.2584 44.695 12.2584 44.4509 12.5025C44.2068 12.7465 44.2068 13.1423 44.4509 13.3863L47.9864 16.9219L44.4509 20.4574C44.2068 20.7015 44.2068 21.0972 44.4509 21.3413C44.695 21.5854 45.0907 21.5854 45.3348 21.3413L49.3123 17.3638ZM19.0273 17.5469H48.8703V16.2969H19.0273V17.5469Z"
        fill="#37322F"
      />
    </svg>
  );
};
