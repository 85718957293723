import style from "./ceilingTypes.module.scss";
import { dataBlocks } from "./constants/dataBlocks";

const CeilingTypes = () => {
  return (
    <section className={style.ceilingTypes}>
      <h1 className={style.ceilingTypes__title}>Виды потолков</h1>
      <p className={style.ceilingTypes__text}>
        Любой выбор — всегда личное дело, выбор потолка — не исключение. Вкус,
        привычки, планировка квартиры, состоятельность и комфорт интерьера —
        всё влияет на предпочтения. Мы не даём советов, какой потолок выбрать —
        мы всего лишь расскажем о том, какие потолки наши клиенты выбирали чаще
        всего и остались очень довольны.
      </p>
      <div className={style.ceilingTypes__wrapperBlocks}>
        {dataBlocks.map((block, index) => {
          return (
            <div className={style.block} key={index}>
              <div className={style.img}>{block.img}</div>
              <div className={style.wrapperText}>
                <h1 className={style.title}>{block.title}</h1>
                <p className={style.text}>{block.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CeilingTypes;
