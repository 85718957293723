import { IconRhombus } from "../../../assets/svg/iconRhombusReviewsCard";
import style from "./reviewsCard.module.scss";

const ReviewsCard = ({ data }) => {
  return (
    <div className={style.reviewsCard}>
      <h4 className={style.reviewsCard__name}>{data.name}</h4>
      <div className={style.reviewsCard__avatar}>{data.avatar}</div>
      <p className={style.reviewsCard__text}>{data.text}</p>
      <div className={style.reviewsCard__iconRhombus}>
        <IconRhombus />
      </div>
    </div>
  );
};

export default ReviewsCard;
