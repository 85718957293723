export const GreenRhombus = () => {
  return (
    <svg
      width="73"
      height="109"
      viewBox="0 0 73 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.00770661"
        y="0.695249"
        width="45.7178"
        height="45.6931"
        transform="matrix(0.710977 0.703216 -0.72639 0.687283 40.0809 9.68377)"
        stroke="#37322F"
      />
      <rect
        x="-0.00770664"
        y="0.695249"
        width="46.1628"
        height="46.1378"
        transform="matrix(0.710977 0.703216 -0.72639 0.687283 34.8036 9.68182)"
        fill="#6DCB34"
        stroke="#FFF4DE"
      />
    </svg>
  );
};

export const YellowRhombus = () => {
  return (
    <svg
      width="73"
      height="109"
      viewBox="0 0 73 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.00770661"
        y="0.695249"
        width="45.7178"
        height="45.6931"
        transform="matrix(0.710977 0.703216 -0.72639 0.687283 40.0809 9.68377)"
        stroke="#37322F"
      />
      <rect
        x="-0.00770664"
        y="0.695249"
        width="46.1628"
        height="46.1378"
        transform="matrix(0.710977 0.703216 -0.72639 0.687283 34.8036 9.68182)"
        fill="#FFB800"
        stroke="#FFF4DE"
      />
    </svg>
  );
};

export const RedRhombus = () => {
  return (
    <svg
      width="73"
      height="109"
      viewBox="0 0 73 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.00770661"
        y="0.695249"
        width="45.7178"
        height="45.6931"
        transform="matrix(0.710977 0.703216 -0.72639 0.687283 40.0809 9.68377)"
        stroke="#37322F"
      />
      <rect
        x="-0.00770664"
        y="0.695249"
        width="46.1628"
        height="46.1378"
        transform="matrix(0.710977 0.703216 -0.72639 0.687283 34.8036 9.68182)"
        fill="#E94343"
        stroke="#FFF4DE"
      />
    </svg>
  );
};
