import { IconSquare } from "../../../../assets/svg/iconSquare";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "../../../common/Buttons/button";
import { dataStages } from "./constants/dataStages";
import workerImg from "../../../../assets/img/servicesPage/workerImg.png";
import workerImgMobile from "../../../../assets/img/servicesPage/workerImgMobile.png";
import style from "./workWithUs.module.scss";
import ModalForm from "../../../common/ModalWindows/ModalForm";

const WorkWithUs = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className={style.workWithUs}>
      <div className={style.wrapperInfo}>
        <h1 className={style.workWithUs__title}>
          Работать с нами надёжно и просто
        </h1>
        <p className={style.workWithUs__subtitle}>
          Замер делается бесплатно и по его результатам мы предоставим вам смету
          на материал и работы. Замерщик приедет в удобное для вас назначенное
          время с каталогом материалов.
        </p>
        <div className={style.workWithUs__wrapperStages}>
          {dataStages.map((stage, index) => {
            return (
              <div className={style.wrapperStage} key={index}>
                <div className={style.svg}>
                  <IconSquare />
                </div>
                <div className={style.wrapperText}>
                  <h3 className={style.title}>{stage.title}</h3>
                  <p className={style.text}>{stage.text}</p>
                </div>
              </div>
            );
          })}
        </div>
        <img
          className={style.workWithUs__imgMobile}
          src={workerImgMobile}
          alt="worker"
        ></img>
        <div className={style.workWithUs__button} onClick={handleOpen}>
          <Button text="Вызвать замерщика" underline="black" />
        </div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={styleBox}>
            <ModalForm
              title="Закажите бесплатный выезд замерщика"
              subtitle="Заполните форму для вызова замерщика"
              handleClose={handleClose}
            />
          </Box>
        </Modal>
      </div>
      <img className={style.workWithUs__img} src={workerImg} alt="worker"></img>
    </section>
  );
};

export default WorkWithUs;
