export const IconRhombus = () => {
  return (
    <svg
      width="95"
      height="87"
      viewBox="0 0 95 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.00774056"
        y="0.698145"
        width="17.2257"
        height="17.2128"
        transform="matrix(0.70813 0.706082 -0.723611 0.690208 47.5029 30.2217)"
        stroke="#37322F"
      />
      <rect
        x="-0.00774056"
        y="0.698145"
        width="17.3993"
        height="17.3862"
        transform="matrix(0.70813 0.706082 -0.723611 0.690208 47.8076 30.2217)"
        fill="#FFB800"
        stroke="#FFF4DE"
      />
    </svg>
  );
};
