export const ClosingIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.339623 29.6604C0.566038 29.8868 0.849056 30 1.13208 30C1.41509 30 1.69811 29.8868 1.92453 29.6604L15 16.5849L28.0755 29.6604C28.3019 29.8868 28.5849 30 28.8679 30C29.1509 30 29.434 29.8868 29.6604 29.6604C30.1132 29.2075 30.1132 28.5283 29.6604 28.0755L16.5849 15L29.6604 1.92453C30.1132 1.4717 30.1132 0.792453 29.6604 0.339623C29.2075 -0.113208 28.5283 -0.113208 28.0755 0.339623L15 13.4151L1.92453 0.339623C1.4717 -0.113208 0.792453 -0.113208 0.339623 0.339623C-0.113208 0.792453 -0.113208 1.4717 0.339623 1.92453L13.4151 15L0.339623 28.0755C-0.113208 28.5283 -0.113208 29.2075 0.339623 29.6604Z"
        fill="#37322F"
      />
    </svg>
  );
};
