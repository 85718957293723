import CustomBreadcrumbs from "../../common/CustomBreadcrumbs";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Catalog from "./Catalog";

import style from "./catalogPage.module.scss";

const CatalogPage = () => {
  return (
    <>
      <div className={style.wrapperHeader}>
        <Header />
      </div>
      <CustomBreadcrumbs currentPage="Каталог" />
      <Catalog />
      <Footer />
    </>
  );
};

export default CatalogPage;
