import lampImg from "../../../../assets/img/lamps/mainLamp.jpg";
import style from "./lamps.module.scss";

const Lamps = () => {
  return (
    <section className={style.lamps}>
      <h1 className={style.lamps__title}>Светильники</h1>
      <div className={style.wrapperTextImg}>
        <p className={style.lamps__text}>
          Освещение – это ваш комфорт и уют. У нас большой выбор различных
          осветительных приборов. На выбор – помимо вкуса заказчика – влияют
          материал полотна, назначение помещения и мощность светильников. Вы
          можете выбрать любой вариант, ниже есть несколько примеров – но это
          далеко не всё, что мы можем смонтировать. А если не знаете, что для
          вашего помещения лучше, мы вам эти варианты предложим.
        </p>
        <img className={style.lamps__img} src={lampImg} alt="lamp"></img>
      </div>
    </section>
  );
};

export default Lamps;
