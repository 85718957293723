import { dataServises } from "./constants/dataServises";
import style from "./services.module.scss";

const Services = () => {
  return (
    <section className={style.services}>
      {dataServises.map((item, index) => {
        return (
          <div className={style.services__item} key={index}>
            <div className={style.icon}>{item.icon}</div>
            <p className={style.text}>{item.text}</p>
          </div>
        );
      })}
    </section>
  );
};

export default Services;
