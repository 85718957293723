import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MainButton from "../../../common/Buttons/mainButton";
import ModalForm from "../../../common/ModalWindows/ModalForm";
import Сalculator from "../../../common/ModalWindows/Сalculator";
import style from "./description.module.scss";

const Description = () => {
  const [openBackCall, setOpenBackCall] = useState(false);
  const handleOpenBackCall = () => setOpenBackCall(true);
  const handleCloseBackCall = () => setOpenBackCall(false);
  const [openСalculator, setOpenСalculator] = useState(false);
  const handleOpenСalculator = () => setOpenСalculator(true);
  const handleCloseСalculator = () => setOpenСalculator(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const styleBoxCalculator = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className={style.description}>
      <h1 className={style.description__mainTitle}>Натяжные Потолки </h1>
      <h1 className={style.description__secondaryTitle}>в Твери и области</h1>
      <p className={style.description__subtitle}>
        Первое, что мы видим, когда просыпаемся утром — это потолок. Хочется,
        чтобы первое, что увидишь с утра, было надёжным, чистым и светлым.
        Задавало такой настрой на жизнь. ПотолНат делает именно такие потолки.
      </p>
      <div className={style.description__wrapperButton}>
        <div className={style.calculatorBtn} onClick={handleOpenСalculator}>
          <MainButton text="Калькулятор" theme="light" />
        </div>

        <div className={style.orderСallBtn} onClick={handleOpenBackCall}>
          <MainButton text="Заказать звонок" theme="dark" />
        </div>
        <Modal open={openBackCall} onClose={handleCloseBackCall}>
          <Box sx={styleBox}>
            <ModalForm
              title="обратный звонок"
              subtitle="Вы можете задать вопрос или получить консультацию, заказав обратный звонок"
              handleClose={handleCloseBackCall}
            />
          </Box>
        </Modal>
        <Modal open={openСalculator} onClose={handleCloseСalculator}>
          <Box sx={styleBoxCalculator}>
            <Сalculator handleClose={handleCloseСalculator} />
          </Box>
        </Modal>
      </div>
    </section>
  );
};

export default Description;
