export const dataStages = [
  { title: "Заявка", text: "Оставьте заявку на сайте или позвоните нам" },
  {
    title: "Бесплатный замер",
    text: "Наш технолог делает замер и определяет объём работы",
  },
  {
    title: "Договор",
    text: "Вы выбираете образцы полотна и мы заключаем договор",
  },
  {
    title: "Монтаж",
    text: "Наши мастера выезжают к вам и выполняют монтаж потолка",
  },
];
