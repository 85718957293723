import Button from "../../../common/Buttons/button";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import repairImg from "../../../../assets/img/servicesPage/repairImg.png";
import repairImgMobile from "../../../../assets/img/servicesPage/repairImgMobile.png";
import outlineLeft from "../../../../assets/img/servicesPage/outlineLeft.png";
import outlineRight from "../../../../assets/img/servicesPage/outlineRight.png";

import style from "./repair.module.scss";
import { dataRepair } from "./constants/dataRepair";
import ModalForm from "../../../common/ModalWindows/ModalForm";

const Repair = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className={style.repair}>
      <div className={style.wrapperRepair}>
        <h1 className={style.wrapperRepair__title}>ремонт</h1>
        <p className={style.wrapperRepair__subtitle}>
          Мы вашему потолку в частности — и дому в общем — желаем сто лет
          прожить без бед. Но иногда беспечные соседи, особенности и здания
          и прочие факторы могут привести к ремонту. Мы и его проводим быстро
          и качественно — как и всё остальное.
        </p>
        <div className={style.wrapperRepair__button} onClick={handleOpen}>
          <Button text="Оставить заявку" underline="black" />
        </div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={styleBox}>
            <ModalForm
              title="обратный звонок"
              subtitle="Вы можете задать вопрос или получить консультацию, заказав обратный звонок"
              handleClose={handleClose}
            />
          </Box>
        </Modal>
        <img
          className={style.wrapperRepair__repairImg}
          src={repairImg}
          alt="repair"
        ></img>
        <img
          className={style.wrapperRepair__repairImgMobile}
          src={repairImgMobile}
          alt="repair"
        ></img>
        <div className={style.wrapperRepair__points}>
          {dataRepair.map((point, index) => {
            return (
              <div className={style.point} key={index}>
                <div className={style.wrapperTitle}>
                  <div className={style.icon}>{point.icon}</div>
                  <h3 className={style.title}>{point.title}</h3>
                </div>
                <p className={style.text}>{point.text}</p>
              </div>
            );
          })}
        </div>
      </div>
      <img className={style.outlineLeft} src={outlineLeft} alt="outline"></img>
      <img
        className={style.outlineRight}
        src={outlineRight}
        alt="outline"
      ></img>
    </section>
  );
};

export default Repair;
