import size from "../../../../../assets/img/size.jpg";
import colors from "../../../../../assets/img/colors.jpg";
import agreement from "../../../../../assets/img/agreement.jpg";
import deadlines from "../../../../../assets/img/deadlines.jpg";

export const dataServises = [
  {
    text: (
      <span>
        бесплатный <br /> выезд на замер
      </span>
    ),
    icon: <img src={size} alt="size icon" width="100%" height="100%"></img>,
  },
  {
    text: "разнообразие цветов и фактур",
    icon: <img src={colors} alt="colors icon" width="100%" height="100%"></img>,
  },
  {
    text: (
      <span>
        договор <br /> и гарантии
      </span>
    ),
    icon: (
      <img
        src={agreement}
        alt="agreement icon"
        width="100%"
        height="100%"
      ></img>
    ),
  },
  {
    text: "соблюдение сроков",
    icon: (
      <img
        src={deadlines}
        alt="deadlines icon"
        width="100%"
        height="100%"
      ></img>
    ),
  },
];
