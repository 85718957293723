import { IconSquare } from "../../../../../assets/svg/iconSquare";

export const dataPoints = [
  {
    icon: <IconSquare />,
    text: "типа потолочного материала;",
  },
  {
    icon: <IconSquare />,
    text: "площади и конфигурации помещения;",
  },
  {
    icon: <IconSquare />,
    text: "количества углов, труб и светильников, встраиваемых в конфигурацию;",
  },
  {
    icon: <IconSquare />,
    text: "сложности конструкции и работы",
  },
];
