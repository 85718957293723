import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/pagination";

import style from "./reviews.module.scss";
import ReviewsCard from "../../../common/ReviewsCard";
import { dataReviews } from "./constants/dataReviews";
import { useEffect, useState } from "react";

const Reviews = () => {
  const [slidesPerView, setSlidesPerView] = useState(3.1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1020) {
        setSlidesPerView(2);
        if (window.innerWidth <= 600) {
          setSlidesPerView(1);
        }
      }
    };

    // Обработчик события изменения размера окна при монтировании компонента
    window.addEventListener("resize", handleResize);

    // Обработчик события при размонтировании компонента
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [slidesPerView]);
  return (
    <section className={style.reviews}>
      <div className={style.wrapperReviews}>
        <h1 className={style.reviews__title}>что говорят о нас наши клиенты</h1>

        <Swiper
          pagination={{
            clickable: true,
            bulletClass: `${style.customBullet} swiper-pagination-bullet`,
            bulletActiveClass: `${style.customBulletActive} swiper-pagination-bullet-active`,
          }}
          speed="1500"
          slidesPerView={slidesPerView}
          spaceBetween={slidesPerView < 3 ? 0 : 50}
          modules={[Pagination]}
          className={style.mySwiper}
        >
          {dataReviews.map((card, index) => {
            return (
              <SwiperSlide
                key={index}
                className={`${style.mySwiperSlide} swiper-slide`}
              >
                <ReviewsCard data={card} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Reviews;
