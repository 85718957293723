import { IconSquare } from "../../../../assets/svg/iconSquare";
import {
  dataUniqueSolution,
  dataUniqueSolutionMobile,
} from "./constants/dataUniqueSolution";
import uniqueSolutionImg1 from "../../../../assets/img/uniqueSolutionImg1.jpg";
import uniqueSolutionImg2 from "../../../../assets/img/uniqueSolutionImg2.jpg";
import uniqueSolutionImg3 from "../../../../assets/img/uniqueSolutionImg3.jpg";
import style from "./uniqueSolution.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const UniqueSolution = () => {
  return (
    <section className={style.uniqueSolution}>
      <h1 className={style.uniqueSolution__title}>Уникальность решения</h1>
      <p className={style.uniqueSolution__subtitle}>
        Натяжные потолки — уникальное, удобное и очень многообразное решение.
      </p>
      <div className={style.uniqueSolution__wrapperBlocks}>
        {dataUniqueSolution.map((block, index) => {
          return (
            <div className={style.block} key={index}>
              <div className={style.wrapperTitle}>
                <IconSquare />
                <h3 className={style.title}>{block.title}</h3>
              </div>
              <p className={style.text}>{block.text}</p>
            </div>
          );
        })}
      </div>
      <div className={style.uniqueSolution__wrapperBlocksMobile}>
        <Swiper
          pagination={{
            clickable: true,
            bulletClass: `${style.customBullet} swiper-pagination-bullet`,
            bulletActiveClass: `${style.customBulletActive} swiper-pagination-bullet-active`,
          }}
          speed="1500"
          modules={[Pagination]}
        >
          {dataUniqueSolutionMobile.map((slide, index) => {
            return (
              <SwiperSlide
                className={`${style.mySwiperSlideMobile} swiper-slide`}
                key={index}
              >
                {slide.map((block, index) => {
                  return (
                    <div className={style.block} key={index}>
                      <div className={style.wrapperTitle}>
                        <IconSquare />
                        <h3 className={style.title}>{block.title}</h3>
                      </div>
                      <p className={style.text}>{block.text}</p>
                    </div>
                  );
                })}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <img className={style.img1} src={uniqueSolutionImg1} alt="ceilings"></img>
      <img className={style.img2} src={uniqueSolutionImg2} alt="ceilings"></img>
      <img className={style.img3} src={uniqueSolutionImg3} alt="ceilings"></img>
    </section>
  );
};

export default UniqueSolution;
