import ceilingDescriptionImgMatte from "../../../../../assets/img/catalogPage/matte/ceilingDescriptionImgMatte.png";
import ceilingDescriptionImgVarnish from "../../../../../assets/img/catalogPage/varnish/ceilingDescriptionImgVarnish.png";
import ceilingDescriptionImgFabric from "../../../../../assets/img/catalogPage/fabric/ceilingDescriptionImgFabric.png";
import ceilingDescriptionImgColor from "../../../../../assets/img/catalogPage/color/ceilingDescriptionImgColor.png";
import ceilingDescriptionImgMultiLevel from "../../../../../assets/img/catalogPage/multiLevel/ceilingDescriptionImgMultiLevel.png";
import mattePhoto1 from "../../../../../assets/img/catalogPage/matte/mattePhoto1.jpg";
import mattePhoto2 from "../../../../../assets/img/catalogPage/matte/mattePhoto2.jpg";
import mattePhoto3 from "../../../../../assets/img/catalogPage/matte/mattePhoto3.jpg";
import mattePhoto4 from "../../../../../assets/img/catalogPage/matte/mattePhoto4.jpg";
import mattePhoto5 from "../../../../../assets/img/catalogPage/matte/mattePhoto5.jpg";
import mattePhoto6 from "../../../../../assets/img/catalogPage/matte/mattePhoto6.jpg";
import varnishPhoto1 from "../../../../../assets/img/catalogPage/varnish/varnishPhoto1.jpg";
import varnishPhoto2 from "../../../../../assets/img/catalogPage/varnish/varnishPhoto2.jpg";
import varnishPhoto3 from "../../../../../assets/img/catalogPage/varnish/varnishPhoto3.jpg";
import varnishPhoto4 from "../../../../../assets/img/catalogPage/varnish/varnishPhoto4.jpg";
import varnishPhoto5 from "../../../../../assets/img/catalogPage/varnish/varnishPhoto5.jpg";
import varnishPhoto6 from "../../../../../assets/img/catalogPage/varnish/varnishPhoto6.jpg";
import fabricPhoto1 from "../../../../../assets/img/catalogPage/fabric/fabricPhoto1.jpg";
import fabricPhoto2 from "../../../../../assets/img/catalogPage/fabric/fabricPhoto2.jpg";
import fabricPhoto3 from "../../../../../assets/img/catalogPage/fabric/fabricPhoto3.jpg";
import fabricPhoto4 from "../../../../../assets/img/catalogPage/fabric/fabricPhoto4.jpg";
import fabricPhoto5 from "../../../../../assets/img/catalogPage/fabric/fabricPhoto5.jpg";
import fabricPhoto6 from "../../../../../assets/img/catalogPage/fabric/fabricPhoto6.jpg";
import colorPhoto1 from "../../../../../assets/img/catalogPage/color/colorPhoto1.jpg";
import colorPhoto2 from "../../../../../assets/img/catalogPage/color/colorPhoto2.jpg";
import colorPhoto3 from "../../../../../assets/img/catalogPage/color/colorPhoto3.jpg";
import colorPhoto4 from "../../../../../assets/img/catalogPage/color/colorPhoto4.jpg";
import colorPhoto5 from "../../../../../assets/img/catalogPage/color/colorPhoto5.jpg";
import colorPhoto6 from "../../../../../assets/img/catalogPage/color/colorPhoto6.jpg";
import multiLevelPhoto1 from "../../../../../assets/img/catalogPage/multiLevel/multiLevelPhoto1.jpg";
import multiLevelPhoto2 from "../../../../../assets/img/catalogPage/multiLevel/multiLevelPhoto2.jpg";
import multiLevelPhoto3 from "../../../../../assets/img/catalogPage/multiLevel/multiLevelPhoto3.jpg";
import multiLevelPhoto4 from "../../../../../assets/img/catalogPage/multiLevel/multiLevelPhoto4.jpg";
import multiLevelPhoto5 from "../../../../../assets/img/catalogPage/multiLevel/multiLevelPhoto5.jpg";
import multiLevelPhoto6 from "../../../../../assets/img/catalogPage/multiLevel/multiLevelPhoto6.jpg";
import {
  dataCardsColor,
  dataCardsFabric,
  dataCardsMatte,
  dataCardsMultiLevel,
  dataCardsVarnish,
} from "../../../MainPage/OurWorks/constants/dataCards";

export const dataCatalogMatte = {
  ceilingDescription: {
    titleYellow: "матовый потолок — ",
    titleBlack: "красота без излишеств!",
    text1:
      "Классический потолок, по внешнему облику мало отличающийся от других видов, остаётся популярным выбором многих покупателей. Его простота и знакомый стиль делают его доступным и ценово привлекательным для широкого круга потребителей.",
    text2:
      "Кроме того, он продолжает быть лидером продаж среди всех видов натяжных потолков благодаря своей универсальности и способности легко сочетаться с различными стилями интерьера. Это позволяет классическому потолку оставаться востребованным выбором для обновления интерьера, придавая ему уют и сохраняя традиционный внешний вид.",
    img: (
      <img
        src={ceilingDescriptionImgMatte}
        alt="ceiling"
        style={{ width: "100%" }}
      ></img>
    ),
  },
  photoCeilings: {
    title: "матовых",
    blocks: [
      {
        img: <img src={mattePhoto1} alt="ceiling"></img>,
        text: "Белый матовый потолок с точечными светильниками",
      },

      {
        img: <img src={mattePhoto2} alt="ceiling"></img>,
        text: "Белый матовый потолок со световыми линиями",
      },

      {
        img: <img src={mattePhoto3} alt="ceiling"></img>,
        text: "Белый матовый потолок в просторной гостиной",
      },

      {
        img: <img src={mattePhoto4} alt="ceiling"></img>,
        text: "Белый матовый потолок в небольшой спальне",
      },

      {
        img: <img src={mattePhoto5} alt="ceiling"></img>,
        text: "Белый матовый потолок с точечными светильниками",
      },

      {
        img: <img src={mattePhoto6} alt="ceiling"></img>,
        text: "Белый матовый потолок в просторной гостиной",
      },
    ],
  },
  latestProjects: dataCardsMatte,
};

export const dataCatalogVarnish = {
  ceilingDescription: {
    titleYellow: "лаковый потолок — ",
    titleBlack: "блеск в каждом уголке!",
    text1:
      "Вторые по популярности потолки обладают отражающей поверхностью, создавая зеркальный эффект и визуально увеличивая пространство, что представляет интересный дизайнерский элемент, придающий ощущение простора.",
    text2:
      "Эти потолки представляют собой доступное и экономичное решение, что делает их привлекательным выбором для тех, кто ищет эффективные дизайнерские возможности без больших затрат. Совокупность визуального расширения пространства и доступной цены делает их привлекательными для тех, кто стремится к современному и стильному оформлению интерьера.",
    img: (
      <img
        src={ceilingDescriptionImgVarnish}
        alt="ceiling"
        style={{ width: "100%" }}
      ></img>
    ),
  },
  photoCeilings: {
    title: "лаковых",
    blocks: [
      {
        img: <img src={varnishPhoto1} alt="ceiling"></img>,
        text: "Бежевый лаковый потолок с точечными светильниками",
      },
      {
        img: <img src={varnishPhoto2} alt="ceiling"></img>,
        text: "Белый лаковый потолок с люстрой в небольшой гостиной",
      },
      {
        img: <img src={varnishPhoto3} alt="ceiling"></img>,
        text: "Белый лаковый потолок со световыми линиями",
      },
      {
        img: <img src={varnishPhoto4} alt="ceiling"></img>,
        text: "Белый лаковый потолок в ванной комнате",
      },
      {
        img: <img src={varnishPhoto5} alt="ceiling"></img>,
        text: "Белый лаковый потолок с точечными светильниками",
      },
      {
        img: <img src={varnishPhoto6} alt="ceiling"></img>,
        text: "Белый лаковый потолок с точечными светильниками",
      },
    ],
  },
  latestProjects: dataCardsVarnish,
};

export const dataCatalogFabric = {
  ceilingDescription: {
    titleYellow: "тканевый потолок — ",
    titleBlack: "уют с элегантностью!",
    text1:
      "В большинстве случаев выбор цвета этих потолков падает на белый цвет. Такие потолки, называемые бесшовными, выделяются своей способностью охватывать бесшовные перекрытия протяжённостью более пяти метров, создавая тем самым стильный и гармоничный визуальный эффект в интерьере.",
    text2:
      "Некоторые выбирают этот тип потолка из-за экологичности на контрасте в ПВХ плёнкой — но это не совсем так, ткань тоже пропитывается определённым составом. А экологичность подтверждена сертификатами для всех типов потолков. Тканевые потолки подчёркивают предпочтения особо — они одни из самых дорогих.",
    img: (
      <img
        src={ceilingDescriptionImgFabric}
        alt="ceiling"
        style={{ width: "100%" }}
      ></img>
    ),
  },
  photoCeilings: {
    title: "тканевых",
    blocks: [
      {
        img: <img src={fabricPhoto1} alt="ceiling"></img>,
        text: "Тканевый потолок с точечными светильниками в гостиной",
      },
      {
        img: <img src={fabricPhoto2} alt="ceiling"></img>,
        text: "Тканевый потолок в просторной гостиной",
      },
      {
        img: <img src={fabricPhoto3} alt="ceiling"></img>,
        text: "Белый тканевый потолок в небольшом холле",
      },
      {
        img: <img src={fabricPhoto4} alt="ceiling"></img>,
        text: "Тканевый потолок в просторной спальне",
      },
      {
        img: <img src={fabricPhoto5} alt="ceiling"></img>,
        text: "Белый тканевый потолок с точечными светильниками",
      },
      {
        img: <img src={fabricPhoto6} alt="ceiling"></img>,
        text: "Белый тканевый потолок с точечными светильниками",
      },
    ],
  },
  latestProjects: dataCardsFabric,
};

export const dataCatalogColor = {
  ceilingDescription: {
    titleYellow: "цветной потолок — ",
    titleBlack: "вырази свою индивидуальность!",
    text1:
      "Широкий выбор цветных натяжных потолков, представляющих более сотню различных оттенков, открывает безграничные возможности для творчества и индивидуального подхода к оформлению пространства.",
    text2:
      "От классических и спокойных до смелых и насыщенных цветов, этот разнообразный спектр предоставляет клиенту возможность подобрать именно тот оттенок, который подчеркнёт его уникальный стиль и создаст желаемую атмосферу в помещении. Такой выбор цветов для натяжных потолков позволяет каждому клиенту воплотить свои креативные идеи в реальность.",
    img: (
      <img
        src={ceilingDescriptionImgColor}
        alt="ceiling"
        style={{ width: "100%" }}
      ></img>
    ),
  },
  photoCeilings: {
    title: "цветных",
    blocks: [
      {
        img: <img src={colorPhoto1} alt="ceiling"></img>,
        text: "Цветной потолок с точечными светильниками в гостиной",
      },
      {
        img: <img src={colorPhoto2} alt="ceiling"></img>,
        text: "Цветной потолок с точечными светильниками в ванной",
      },
      {
        img: <img src={colorPhoto3} alt="ceiling"></img>,
        text: "Цветной лаковый потолок в небольшой кухне",
      },
      {
        img: <img src={colorPhoto4} alt="ceiling"></img>,
        text: "Цветной лаковый потолок в ванной комнате",
      },
      {
        img: <img src={colorPhoto5} alt="ceiling"></img>,
        text: "Цветной потолок с точечными светильниками в кухне",
      },
      {
        img: <img src={colorPhoto6} alt="ceiling"></img>,
        text: "Цветной потолок с точечными светильниками в ванной",
      },
    ],
  },
  latestProjects: dataCardsColor,
};

export const dataCatalogMultiLevel = {
  ceilingDescription: {
    titleYellow: "многоуровневый потолок — ",
    titleBlack: "объём и стиль!",
    text1:
      "Популярность таких потолков, скорее всего, будет ограничена из-за их высокой стоимости, которая оказывается самой высокой в своём классе. Однако эти потолки представляют собой нечто более, чем просто отделочное решение — они необычные концепции, разработанные для создания уникальных интерьеров.",
    text2:
      "Эти потолки высоко ценятся любителями искусства и дизайна за их возможность трансформировать обыденные пространства в произведения искусства. Вложение в эти потолки становится выражением стиля и роскоши, подчёркивая декоративный потенциал их сложных форм и оригинальных линий.",
    img: (
      <img
        src={ceilingDescriptionImgMultiLevel}
        alt="ceiling"
        style={{ width: "100%" }}
      ></img>
    ),
  },
  photoCeilings: {
    title: "многоуровневых",
    blocks: [
      {
        img: <img src={multiLevelPhoto1} alt="ceiling"></img>,
        text: "Многоуровневый лаковый потолок с точечными светильниками",
      },
      {
        img: <img src={multiLevelPhoto2} alt="ceiling"></img>,
        text: "Многоуровневый лаковый потолок с точечными светильниками",
      },
      {
        img: <img src={multiLevelPhoto3} alt="ceiling"></img>,
        text: "Многоуровневый матовый потолок с точечными светильниками",
      },
      {
        img: <img src={multiLevelPhoto4} alt="ceiling"></img>,
        text: "Многоуровневый матовый потолок в небольшой гостиной",
      },
      {
        img: <img src={multiLevelPhoto5} alt="ceiling"></img>,
        text: "Многоуровневый потолок с точечными светильниками",
      },
      {
        img: <img src={multiLevelPhoto6} alt="ceiling"></img>,
        text: "Многоуровневый лаковый потолок в небольшой кухне",
      },
    ],
  },
  latestProjects: dataCardsMultiLevel,
};
