import CardOurWorks from "../../../common/CardOurWorks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
import style from "./latestProjects.module.scss";

const LatestProjects = ({ dataLatestProjects }) => {
  const [slidesPerView, setSlidesPerView] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 570) {
        setSlidesPerView(1);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [slidesPerView]);
  return (
    <section className={style.latestProjects}>
      <h1 className={style.latestProjects__title}>наши последние проекты</h1>
      <div className={style.latestProjects__wrapperCards}>
        {dataLatestProjects.map((card, index) => {
          return <CardOurWorks data={card} key={index} />;
        })}
      </div>
      <div className={style.latestProjects__wrapperBlocksMobile}>
        <Swiper
          pagination={{
            clickable: true,
            bulletClass: `${style.customBullet} swiper-pagination-bullet`,
            bulletActiveClass: `${style.customBulletActive} swiper-pagination-bullet-active`,
          }}
          speed="1500"
          slidesPerView={slidesPerView}
          spaceBetween={20}
          style={{ padding: "5px" }}
          modules={[Pagination]}
        >
          {dataLatestProjects.map((slide, index) => {
            return (
              <SwiperSlide
                className={`${style.mySwiperSlideMobile} swiper-slide`}
                key={index}
              >
                <CardOurWorks data={slide} key={index} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default LatestProjects;
