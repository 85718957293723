import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import "./styles/_App.scss";
import MainPage from "./components/layouts/MainPage/index";
import LampsPage from "./components/layouts/LampsPage";
import { useEffect } from "react";
import PricePage from "./components/layouts/PricePage";
import PrivacyPolicyPage from "./components/layouts/PrivacyPolicyPage";
import ServicesPage from "./components/layouts/ServicesPage";
import CatalogPage from "./components/layouts/CatalogPage";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/catalog/:selected" element={<CatalogPage />} />
          <Route path="/lamps" element={<LampsPage />} />
          <Route path="/prices" element={<PricePage />} />
          <Route path="/services" element={<ServicesPage />} />

          <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
