export const MailLogo = () => {
  return (
    <svg
      width="138"
      height="128"
      viewBox="0 0 138 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="74.5497"
        height="74.5539"
        transform="matrix(0.721176 0.692752 -0.728501 0.685044 59.0645 25.2852)"
        fill="#FFB800"
      />
      <rect
        width="14.9099"
        height="80.7667"
        transform="matrix(0.721176 0.692752 -0.728501 0.685044 84.9482 0.5)"
        fill="#FFB800"
      />
      <rect
        x="-0.00366274"
        y="0.688898"
        width="79.7621"
        height="79.7667"
        transform="matrix(0.721176 0.692752 -0.728501 0.685044 59.5887 5.67654)"
        fill="#37322F"
        stroke="#FFBD00"
      />
      <rect
        x="-0.00285667"
        y="0.537291"
        width="24.07"
        height="24.0714"
        transform="matrix(0.721176 0.692752 -0.728501 0.685044 120.218 88.6439)"
        stroke="#FFB800"
        strokeWidth="0.779929"
      />
      <path
        d="M51.7055 70.375V87H48.808V79.9938H43.7255V87H40.828V70.375H43.7255V77.2863H48.808V70.375H51.7055ZM62.2889 83.295H57.3252L56.3039 87H53.3589L58.2514 70.375H61.3627L66.2552 87H63.3102L62.2889 83.295ZM61.5527 80.6112L59.8189 74.2225L58.0614 80.6112H61.5527ZM76.2997 73.0588H72.4285V87H69.531V73.0588H65.6122V70.375H76.2997V73.0588Z"
        fill="#FFB800"
      />
      <path
        d="M38.9477 46.2891V62.9141H36.0502V48.9966H30.9677V62.9141H28.0702V46.2891H38.9477ZM50.1961 46.2891C50.7186 46.2891 51.1461 46.4553 51.4786 46.7878C51.7953 47.1045 51.9536 47.5082 51.9536 47.9991V61.2041C51.9536 61.6791 51.7953 62.0828 51.4786 62.4153C51.1461 62.7478 50.7186 62.9141 50.1961 62.9141H43.0236C42.5169 62.9141 42.0973 62.7478 41.7648 62.4153C41.4323 62.0828 41.2661 61.6791 41.2661 61.2041V47.9991C41.2661 47.5082 41.4323 47.1045 41.7648 46.7878C42.0973 46.4553 42.5169 46.2891 43.0236 46.2891H50.1961ZM49.0561 49.4478C49.0561 49.1311 48.9057 48.9728 48.6048 48.9728H44.6148C44.314 48.9728 44.1636 49.1311 44.1636 49.4478V59.7553C44.1636 60.072 44.314 60.2303 44.6148 60.2303H48.6048C48.9057 60.2303 49.0561 60.072 49.0561 59.7553V49.4478ZM64.5624 48.9728H60.6912V62.9141H57.7937V48.9728H53.8749V46.2891H64.5624V48.9728ZM75.4305 46.2891C75.953 46.2891 76.3805 46.4553 76.713 46.7878C77.0296 47.1045 77.188 47.5082 77.188 47.9991V61.2041C77.188 61.6791 77.0296 62.0828 76.713 62.4153C76.3805 62.7478 75.953 62.9141 75.4305 62.9141H68.258C67.7513 62.9141 67.3317 62.7478 66.9992 62.4153C66.6667 62.0828 66.5005 61.6791 66.5005 61.2041V47.9991C66.5005 47.5082 66.6667 47.1045 66.9992 46.7878C67.3317 46.4553 67.7513 46.2891 68.258 46.2891H75.4305ZM74.2905 49.4478C74.2905 49.1311 74.1401 48.9728 73.8392 48.9728H69.8492C69.5484 48.9728 69.398 49.1311 69.398 49.4478V59.7553C69.398 60.072 69.5484 60.2303 69.8492 60.2303H73.8392C74.1401 60.2303 74.2905 60.072 74.2905 59.7553V49.4478ZM78.8006 62.9141L83.6931 46.2891H86.8043L91.6968 62.9141H88.7518L85.2368 50.1366L81.7456 62.9141H78.8006Z"
        fill="#FFB800"
      />
    </svg>
  );
};
