import style from "./ceilingDescription.module.scss";

const CeilingDescription = ({ dataCeilingDescription }) => {
  return (
    <div className={style.ceilingDescription}>
      <h3 className={style.ceilingDescription__title}>
        <span className={style.yellow}>
          {dataCeilingDescription.titleYellow}
        </span>
        <span className={style.black}>{dataCeilingDescription.titleBlack}</span>
      </h3>
      <div className={style.ceilingDescription__wrapperTextAndImg}>
        <div className={style.wrapperText}>
          <p className={style.text}>{dataCeilingDescription.text1}</p>
          <p className={style.text}>{dataCeilingDescription.text2}</p>
        </div>
        <div className={style.img}>{dataCeilingDescription.img}</div>
      </div>
    </div>
  );
};

export default CeilingDescription;
