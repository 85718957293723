import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMedia } from "use-media";

// хук для определения устройства

const useDeviceType = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMedia("(max-width: 1450px)");

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery, location]);

  return { isMobile };
};

export default useDeviceType;
