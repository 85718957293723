import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "../../../common/Buttons/button";
import { dataPointsDesign } from "./constants/dataPointsDesign";
import designImgTablet from "../../../../assets/img/servicesPage/designImgTablet.png";

import style from "./design.module.scss";
import ModalForm from "../../../common/ModalWindows/ModalForm";

const Design = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className={style.design}>
      <h1 className={style.design__title}>дизайн</h1>
      <div className={style.design__wrapper}>
        <p className={style.text}>
          Сегодня при оформлении помещений все чаще используют натяжные полотна,
          поскольку они привлекают разнообразием, позволяя воплотить
          в реальность необычные и креативные идеи. Современный дизайн натяжных
          потолков придаёт помещению особенную атмосферу, делает его уютным
          и стильным. Эта деталь декора способна преобразить комнату, стать
          её изюминкой.
        </p>
        <div className={style.wrapperPoints}>
          <img
            className={style.designImgTablet}
            src={designImgTablet}
            alt="ceilings"
          ></img>
          {dataPointsDesign.map((point, index) => {
            return (
              <div className={style.point} key={index}>
                <h3 className={style.title}>{point.title}</h3>
                <div className={style.wrapperTextAndImg}>
                  <div className={style.img}>{point.img}</div>
                  <p className={style.text}>{point.text}</p>
                </div>
              </div>
            );
          })}
        </div>
        <p className={style.text}>
          Помощь с выбором лучшего варианта конкретно под ваш запрос, быстрый
          замер и установка в удобное для вас время, аккуратно, с гарантией, –
          это ПотолНат. Обращайтесь!
        </p>
      </div>
      <div className={style.design__button} onClick={handleOpen}>
        <Button text="Заказать звонок" underline="black" />
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={styleBox}>
          <ModalForm
            title="обратный звонок"
            subtitle="Вы можете задать вопрос или получить консультацию, заказав обратный звонок"
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </section>
  );
};

export default Design;
