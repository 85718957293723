import mainImg from "../../../../assets/img/bestSolution/mainImg.png";
import { IconSquare } from "../../../../assets/svg/iconSquare";

import style from "./bestSolution.module.scss";
import { dataBestSolution } from "./constants/dataBestSolution";

const BestSolution = () => {
  return (
    <section className={style.bestSolution}>
      <div className={style.wrapperBestSolution}>
        <h1 className={style.bestSolution__title}>
          Натяжной потолок — лучшее решение из возможных
        </h1>
        <img
          className={style.bestSolution__mainImg}
          src={mainImg}
          alt="mainImg"
        ></img>
        <div className={style.bestSolution__wrapperBlocks}>
          {dataBestSolution.map((block, index) => {
            return (
              <div className={style.block} key={index}>
                <div className={style.iconSquare}>
                  <IconSquare />
                </div>
                <div className={style.wrapperText}>
                  <h3 className={style.title}>{block.title}</h3>
                  <p className={style.text}>{block.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BestSolution;
