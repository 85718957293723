export const IconSquaresInDesign = () => {
  return (
    <svg
      width="144"
      height="118"
      viewBox="0 0 144 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.00498999"
        y="0.938251"
        width="83.6424"
        height="83.6424"
        transform="matrix(0.719073 0.694934 -0.726424 0.687247 83.4263 0.296909)"
        stroke="black"
        strokeWidth="1.35764"
      />
      <rect
        x="-0.00311347"
        y="0.585416"
        width="84.1529"
        height="84.1529"
        transform="matrix(0.719072 0.694935 -0.726423 0.687248 62.1705 0.185254)"
        fill="#FFB800"
        stroke="#FFB800"
        strokeWidth="0.84709"
      />
    </svg>
  );
};
